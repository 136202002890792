import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import "./BlueBox.css";

const BlueBox = (props) => {
  const { stepCss, justImgText, just3Text, just3Para } = props.Bluebox;
  return (
    <Fragment>
      <Col md={4} className="mb-4">
        <div className="just3Box">
          <div className={stepCss}>
            <h5 className="justImgText">{justImgText}</h5>
          </div>
          <div className="ContentWrapper">
            <h3 className="just3Text">{just3Text}</h3>
            <p className="just3Para">{just3Para}</p>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default BlueBox;
