import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import "./FdBoxes.css";

const FdBoxes = (props) => {
  const {
    FdBoxe1Head,
    FdBoxe1btn1,
    FdBoxe1btn2,
    FdBoxe2Head,
    FdBoxe2btn1,
    FdBoxe2btn2,
  } = props;
  return (
    <Fragment>
      {" "}
      <Row>
        <Col xs={11} className="mx-auto FDAApproved-width">
          <Row>
            <Col md={6} className="MarginN5 mb-5">
              <div className="innerWrapperr just3BoxImg1">
                <h1 className="BannerSub_heading">{FdBoxe1Head}</h1>
                <div className="d-flex align-items-center">
                  <button className="HeroBtn HarrowBtn FDAApprovedBtn me-4">
                    {FdBoxe1btn1}
                  </button>
                  <button className="innerWrapperrBtn FDAApprovedBtn">
                    {FdBoxe1btn2}
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6} className="MarginN5 ">
              <div className="innerWrapperr just3BoxImg2">
                <h1 className="BannerSub_heading">{FdBoxe2Head}</h1>
                <div className="d-flex align-items-center">
                  <button className="HeroBtn HarrowBtn me-4 FDAApprovedBtn">
                    {FdBoxe2btn1}
                  </button>
                  <button className="innerWrapperrBtn FDAApprovedBtn">
                    {FdBoxe2btn2}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FdBoxes;
