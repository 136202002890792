import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueBox from "../blueBox/BlueBox";
import "./HpJust3Steps.css";

const HpJust3Steps = (props) => {
  const { justThreeHead, justThreeHeadArray } = props;
  return (
    <Fragment>
      {/* just three steps */}
      <Container fluid className="justThreeStepsBg py-5">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="justThreeHead">{justThreeHead}</h2>
            <Row>
              <BlueBox Bluebox={justThreeHeadArray[0]} />
              <BlueBox Bluebox={justThreeHeadArray[1]} />
              <BlueBox Bluebox={justThreeHeadArray[2]} />
            </Row>
          </Col>
        </Row>
      </Container>
      {/* just three steps end */}
    </Fragment>
  );
};

export default HpJust3Steps;
