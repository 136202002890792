import React from "react";
import "./FooterBtn.css";
const FooterBtn = (props) => {
  const { className, Next, Back } = props;
  return (
    <div class={`desktop-Buttons-  ${className}`}>
      {Back ? (
        <button onClick={Back} type="button" class="btn first-btn- py-1 px-4">
          Back
        </button>
      ) : (
        <span></span>
      )}
      {Next ? (
        <button onClick={Next} type="button" class="btn second-btn- py-1 px-4">
          Next
        </button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default FooterBtn;
