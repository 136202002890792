import React from "react";
import logo_hippa from "../../../Asset/logo_hippa.png";
import logoScrip from "../../../Asset/logo-Script.png";
import "./Footers.css";
const Footers = () => {
  return (
    <div class="col-md-12 mt-3">
      <p class="footer-text_">
        Your answers are saved securely according to HIPAA and federal law.
      </p>
      <div class="logo-images_">
        <a href="/">
          <img src={logo_hippa} alt="Logo Script" class="img-fluid me-5" />
        </a>
        <a href="/">
          <img src={logoScrip} alt="Logo Hippa" class="img-fluid" />
        </a>
      </div>
    </div>
  );
};

export default Footers;
