import {React, useState} from "react"; 
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step8.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step8 = ({ nextStep, prevStep, handleFormData, updateInputData, values }) => {

  const medicationList = [
    'Not taking any medications',
    'Accupril',
    'Aciphex',
    'Actos',
    'Adalat CC',
    'Adderall',
    'Albuterol',
    'Alesse 28',
    'Allegra',
    'Allegra-D',
    'Allopurinol',
    'Alphagan',
    'Alprazolam',
    'Altace',
    'Amaryl',
    'Ambien',
    'Amitriptyline HCL',
    'Amoxicillin Trihydrate',
    'Amoxil',
    'Amphetamine',
    'Amyl nitrite',
    'Anabolic Steroids',
    'Aricept',
    'Atenolol',
    'Atenolol (Mylan)',
    'Atrovent',
    'Augmentin',
    'Avandia',
    'Avapro',
    'Azmacort',
    'Bactroban',
    'Baycol',
    'Biaxin',
    'Buspar',
    'Cardizem CD',
    'Cardura',
    'Carisoprodol',
    'Ceftin',
    'Cefzil',
    'Celebrex',
    'Celexa',
    'Cephalexin',
    'Cipro',
    'Claritin',
    'Claritin Reditabs',
    'Claritin-D 12HR',
    'Claritin-D 24HR',
    'Climara',
    'Clonazepam',
    'Clonidine HCL',
    'Cocaine',
    'Combivent',
    'Coumadin',
    'Cozaar',
    'Cyclobenzaprine',
    'Depakote',
    'Detrol',
    'Diazepam',
    'Diflucan',
    'Dilantin',
    'Diovan',
    'Diovan HCT',
    'Effexor XR',
    'Elocon',
    'Ery-Tab',
    'Evista',
    'Flomax',
    'Flonase',
    'Flovent',
    'Folic Acid',
    'Fosamax',
    'Furosemide',
    'Gemfibrozil',
    'Glucophage',
    'Glucotrol XL',
    'Glyburide',
    'Hydrochlorothiazide',
    'Hydrocodone',
    'Hyzaar',
    'Ibuprofen',
    'Imitrex',
    'Isosorbide Mononitrate',
    'K-Dur',
    'Ketamine',
    'Klor-Con 10',
    'Lanoxin',
    'Lescol',
    'Levaquin',
    'Levothroid',
    'Levoxyl',
    'Lipitor',
    'Lo Ovral',
    'Loestrin FE',
    'Lorazepam',
    'Lotensin',
    'Lotrel',
    'Lotrisone',
    'Lysergic Acid Diethylamide',
    'Macrobid',
    'Marijuana',
    'MDMA',
    'Medroxyprogesterone',
    'Mescaline',
    'Methamphetamine',
    'Methylphenidate',
    'Methylprednisolone',
    'Metoprolol Tartrate',
    'Miacalcin',
    'Monopril',
    'Morphine',
    'Naproxen',
    'Naproxen-Sodium',
    'Nasonex',
    'Neurontin',
    'Nicotine',
    'Norvasc',
    'Ortho-Cyclen',
    'Ortho-Tri-Cyclen',
    'Oxycodone',
    'OxyContin',
    'Paxil',
    'Penicillin VK',
    'Pepcid',
    'Phenergan',
    'Plavix',
    'Plendil',
    'Potassium chloride',
    'Pravachol',
    'Prednisone',
    'Premarin',
    'Prempro',
    'Prevacid',
    'Prilosec',
    'Prinivil',
    'Procardia-XL',
    'Promethazine',
    'Propoxyphene',
    'Proventil HFA',
    'Prozac',
    'Psilocybin',
    'Ranitidine',
    'Relafen',
    'Remeron',
    'Risperdal',
    'Ritalin',
    'Rohypnol',
    'Roxicet',
    'Serevent',
    'Serzone',
    'Singulair',
    'Synthroid',
    'Tamoxifen Citrate',
    'Temazepam',
    'Tiazac',
    'Tobradex',
    'Toprol XL',
    'Trazodone',
    'Triamterene',
    'Trimox',
    'Triphasil',
    'Ultram',
    'Valtrex',
    'Vasotec',
    'Veetids',
    'Verapamil',
    'Viagra',
    'Vicoprofen',
    'Vioxx',
    'Warfarin Sodium',
    'Wellbutrin SR',
    'Xalatan',
    'Xenical',
    'Zestoretic',
    'Zestril',
    'Ziac',
    'Zithromax',
    'Zithromax Z-PAK',
    'Zocor',
    'Zoloft',
    'Zyprexa',
    'Zyrtec',
  ];

 

  return (
    //    <!-- Desktop 3 Start -->
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* Body Content */}
              <div class="fourth-text- py-3 text-center">
                <p class="">
                  Please select any medications you are currently taking.
                  <br />
                  <br />
                </p>
                <p class="px-4">
                  If your medication is not listed, you can type it and press
                  enter.
                </p>
              </div>
              {/* <!-- chekbox --> */}
              <div class="Dflex">
                <input
                  class="large-size mt-0"
                  type="checkbox"
                  value={values.takingAnyMedications ? "true" : "false" }
                  id="flexCheckDefault"
                  onChange={handleFormData("takingAnyMedications")}
                />
                <label class="form-check-label px-2" for="flexCheckDefault">
                  I'm not currently taking any medications.
                </label>
              </div>
              {/* <!-- input box --> */}
              <input class="form-control form-control-lg mt-4 searchbar- mx-auto" type="text" placeholder="Select from the list or start typing..."  value={values.takingAnyMedicationsName} onChange={handleFormData("takingAnyMedicationsName")}/>
              {/* End Body Content */}
              <FooterBtn
                className="MarginTop20"
                Next={nextStep}
                Back={prevStep}
              />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step8;
