const page2Hero = {
  Innovating: "Innovating the Future of Eye Care",
  OurMission:
    "Our mission is to help patients manage the preservation oftheir sight by providing access to innovative and affordable medicines.",
};
const WeBelieve = {
  WeBbelieveEye: "We believe eye care should be:",
  page2BlueBox: [
    {
      justImgText: "",
      just3Text: "Personalized",
      just3Para:
        " The online visit is secure and vetted by health experts to help the doctor get to know you and find the right treatment for you.",
    },
    {
      justImgText: "",
      just3Text: "Ongoing",
      just3Para:
        " Treatment doesn’t start or end with a prescription. Visionology members get free, unlimited doctor follow-ups.",
    },
    {
      justImgText: "",
      just3Text: "Empowering",
      just3Para:
        " Each online visit will leave you feeling knowledgeable and confident, knowing your vision is in good hands. ",
    },
  ],
};
const AboutVisinology = {
  VisionologyImg: "https://uat.visionology.com/images/About/image-header.png",
  Visionology: "About Visionology",
  VisionologyDescription:
    "Visionology was a direct-to-consumer eye care subsidiary of Harrow Inc. to make medications more accessible and affordable for everyone. Our medications focus on relieving chronic vision problems. All medications are made in our sister pharmacy, ImprimisRx to bring ophthalmic treatments to market. We are proud to have partnered with the brightest and most respected ophthalmologists and optometrists in the world to offer the families of products available to the rest of the world.",
  HarrowImg: "https://uat.visionology.com/images/About/harrow-logo.png",
  HarrowHeading: "Harrow Inc.",
  HarrowDesc:
    " Harrow Health, Inc. (NASDAQ: HROQ) is an ophthalmic-focused healthcare company. It owns and operates ImprimisRx, one of the nation’s leading ophthalmology-focused pharmaceutical businesses.",
  HarrowBtn: " Visit Harrow Inc.",
  ImprimisRxImg: "https://uat.visionology.com/images/About/imprimisRx-logo-01.png",
  ImprimisRxHead: "ImprimisRx",
  ImprimisRxDesc:
    "ImprimisRx has an FDA-Registered 503B Outsourcing Facility dedicated to providing high quality medications to patients and physicians at affordable prices.",
  ImprimisRxBtn: "Visit Harrow Inc.",
};

const VisinologySkip = {
  VisinologySkipHead: "How Visionology Skips the Middle Men",
  VisinologySkipPara:
    "Harrow’s streamlined approach transforms the pharmaceutical and care delivery processes – disempowering third-party middlemen, providing transparency and reducing costs – by focusing on the needs of patients.",
  VisinologySkipB1: "Visit Visionology",
  VisinologySkipB2: "Connect with Care Provider",
  VisinologySkipB3: "Get Your Prescription Shipped to Your Door",
  VisinologySkipB4: " Access to Doctors Real-Time via Text",
  VisinologySkipBtn: "Start Free Online Visit",
};

const PatientFirst = {
  PatientFirstHead: "A Patient First Approach",
  PatientFirstPara:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance. Visionology offers an affordable dry eye solution, Klarity-C. Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  PatientFirstImg: "https://uat.visionology.com/images/About/a-patient-first-approach.jpg",
  EmpoweringHead: " Empowering Ophthalmologists and Optometrists",
  EmpoweringPara:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance. Visionology offers an affordable dry eye solution, Klarity-C. Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  EmpoweringImg: "https://uat.visionology.com/images/About/empowering-ophthalmologists-and-optometrists.jpg",
};

const FDAApproved = {
  FDAApprovedImg: "https://uat.visionology.com/images/About/image-header-2.png",
  FDAApprovedHead: " FDA Approved Ingredients and FDA Regulated Pharmacy.",
  FDAApprovedPara:
    "Visionology offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain,Visionology can offer medications up to 80% off of what you arecurrently paying.",
  FDAApprovedBtn: "Learn More",
};

const FdBoxesData = {
  FdBoxe1Head: "Dry Eye Disease",
  FdBoxe1btn1: "Start My Online Visit",
  FdBoxe1btn2: "Learn More",
  FdBoxe2Head: "SPARCs Diagnostic",
  FdBoxe2btn1: "Start My Online Visit",
  FdBoxe2btn2: "Learn More",
};

const Page2Blog = {
  blogHead: "Featured Articles",
  BlogItem: [
    {
      BlogImg: "https://uat.visionology.com/images/Blog/allergies-and-dry-eye-disease.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/covid-dry-eyes.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/dry-eye-disease-guide.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
  ],
};

export {
  page2Hero,
  WeBelieve,
  AboutVisinology,
  VisinologySkip,
  PatientFirst,
  FDAApproved,
  FdBoxesData,
  Page2Blog,
};
