import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { welcomeback, Having, Having2 } from "./data";
import "./Page9.css";

const Page9 = () => {
  return (
    <Fragment>
      {/* page7header */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={12}>
                <h1 className="Vision">{welcomeback.Welcome}</h1>
                <p className="VisionDescript">{welcomeback.Log}</p>
                <div className="InputBox mb-5">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="InputSearch"
                    name=""
                    id=""
                  />
                  <button className="SearchBtn">{welcomeback.Continue}</button>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={4} className="ms-md-auto">
                <div className="BoxWrapper">
                  <h2 className="BoxHead">{Having.BoxHead}</h2>
                  <button className="BoxBtn">{Having.Speak}</button>
                </div>
              </Col>
              <Col md={4} className="me-md-auto mt-md-0 mt-5">
                <div className="BoxWrapper">
                  <h2 className="BoxHead">{Having2.BoxHead}</h2>
                  <button className="BoxBtn">{Having2.Speak}</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* page7header end */}
    </Fragment>
  );
};

export default Page9;
