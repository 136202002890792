import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MedicationBtn from "../HomePage/medicationBtn/MedicationBtn";
import Medications from "../HomePage/medications/Medications";
import "./Page8.css";
import {
  VisionLoss,
  FDALoss,
  FDALoss1,
  FDALoss2,
  Aqueous,
  Aqueoustear,
  Treatment,
  SPARCStEST,
  Klarity,
  caring,
  Medication,
  StartYourBtn,
} from "./data";
const Page8 = () => {
  return (
    <Fragment>
      {/* page7header */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={5} lg={6}>
                <h1 className="Page7Heading">{VisionLoss.Vision}</h1>
                <p className="Page7SubPara">
                  <img
                    className="img-fluid me-2"
                    src="https://place-hold.it/20x20"
                    alt=""
                  />{" "}
                  {VisionLoss.Medically}
                  <strong>{VisionLoss.st}</strong>
                  {VisionLoss.Deecember}
                </p>
                <p className="Page7Para">{VisionLoss.Copy}</p>
                <button className="Page7RedBtn mb-5">{VisionLoss.Start}</button>
              </Col>
              <Col md={7} lg={6}>
                <h3 className="page7CommonHead">{VisionLoss.Common}</h3>
                <Row>
                  {VisionLoss.DryArr.map((item, index) => {
                    return (
                      <Col key={index} sm={12} className="mb-4">
                        <div className="page7HeadBox">
                          <h5 className="page7HeadBoxHead">{item.Dry}</h5>
                          <button className="page7HeadBoxBtn">
                            {item.Learn}
                          </button>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* page7header end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FDALoss.What}</h2>
                <p className="FdaPara">{FDALoss.Medically}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDALoss.CopyImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FDALoss1.What}</h2>
                <p className="FdaPara">{FDALoss1.Medically}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDALoss1.CopyImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FDALoss2.What}</h2>
                <p className="FdaPara">{FDALoss2.Medically}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDALoss2.CopyImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead w-75">{Aqueous.AqueousHead}</h2>
                <p className="aqueousPara">{Aqueous.ListOut}</p>
                {Aqueous.ListGlumam.map((item, index) => {
                  return (
                    <>
                      <h4 className="aqueousSubHead">{item.aqueousSubHead}</h4>
                      <p className="aqueousPara">{item.aqueousPara}</p>
                    </>
                  );
                })}
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src="https://place-hold.it/298x298"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">{Aqueoustear.AqueousHead}</h2>
                {Aqueoustear.ListGlumam.map((item, index) => {
                  return (
                    <>
                      <p className="aqueousPara">{item.ListOut}</p>
                      <ol>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ol>
                    </>
                  );
                })}
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src="https://place-hold.it/298x298"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">{Treatment.AqueousHead}</h2>
                <p className="aqueousPara">{Treatment.Different}</p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src={Treatment.DifferentImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">{SPARCStEST.SPARCS}</h2>
                <p className="aqueousPara">{SPARCStEST.SPARCS1}</p>
                <p className="aqueousPara">{SPARCStEST.SPARCS2}</p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src={SPARCStEST.DifferentImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* how section */}
      <Container fluid className="SparcsWork HowPage7Bg py-sm-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={12} className="mx-auto">
                <h1 className="BannerW3s_Heading text-center mb-sm-5 pb-5">
                  {Klarity.KlarityhEAD}
                </h1>
              </Col>
              <Col md={6} className=" ">
                {Klarity.KLArr.map((item, index) => {
                  return (
                    <div className="SparcsWorkContent">
                      <h1 className="BannerSub_heading SWorkCHeading">
                        {item.Lubricates}
                      </h1>
                      <p className="BannerSub_Description mt-4">
                        {item.KlarityDescript}
                      </p>
                    </div>
                  );
                })}
              </Col>
              <Col md={6} className="">
                <div className="SparcsWorkCImg"></div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-center my-5 pt-5">
                  <button className="HeroBtn mt-0 me-4">
                    Start Free Online Visit
                  </button>
                  <button className="medicationPhoneBTN">Learn More</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* how section end */}

      {/* blog */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="blogHead">Featured Articles</h2>
            <Row className="mt-5">
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* blogend */}

      {/* caring eye */}
      <Container fluid className="page7CaringBG">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={6}>
                <h2 className="page7CaringHead mt-5">{caring.caringhEAD}</h2>
                <p className="page7CaringPara">{caring.caringDesript}</p>
                <button className="Page7RedBtn">About Us</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* caring eyeend */}

      {/* medication */}
      <Medications {...Medication} />
      <MedicationBtn {...StartYourBtn} />
      {/* medication end */}
    </Fragment>
  );
};

export default Page8;
