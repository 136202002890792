import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Blog.css";

const Blog = (props) => {
  const { blogHead, BlogItem } = props;
  return (
    <Fragment>
      {/* blog */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="blogHead">{blogHead}</h2>
            <Row className="mt-5">
              {BlogItem.map((item, index) => {
                return (
                  <Col key={index} md={4} className="mb-4">
                    <div>
                      <img className="img-fluid" src={item.BlogImg} alt="" />
                      <p className="blogHeadPara">{item.HEALTH}</p>
                      <h4 className="blogBoxHead">{item.TheDifferent}</h4>
                      <p className="blogBoxPara">{item.AfteraPhysician}</p>
                      <button className="blogBoxBtn">
                        {item.BlogReadMore}
                      </button>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row className="align-items-center pt-sm-5 mt-5">
              <Col xs={4} lg={5}>
                <div className="blogLine"></div>
              </Col>
              <Col xs={4} lg={2}>
                <p className="blogLineText">View More posts</p>
              </Col>
              <Col xs={4} lg={5}>
                <div className="blogLine"></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* blog end */}
    </Fragment>
  );
};

export default Blog;
