import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import "./FDAApproved.css";
const Index = (props) => {
  const { FDAApprovedImg, FDAApprovedHead, FDAApprovedPara, FDAApprovedBtn } =
    props;
  return (
    // import sy container lagna zaruri hy
    // <Container fluid className="FDAApprovedContent px-0">
    <Fragment>
      <img src={FDAApprovedImg} className="img-fluid" alt="" />
      <Row className="mx-0">
        <Col xs={11} className="mx-auto FDAApproved-width">
          <Row className="FDACWrapper mx-0 marginN10">
            <Col lg={6} className="">
              <h1 className="BannerSub_heading FDACWHeading">
                {FDAApprovedHead}
              </h1>
            </Col>
            <Col lg={6} className="">
              <p className="FDACWrapper_Description">{FDAApprovedPara}</p>
              <button className="HeroBtn mt-4">{FDAApprovedBtn}</button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Index;
