const page5Header = {
  page5headerhead: "Tracking and Diagnosing Glaucoma from home",
  page5headerSubhead: "Glaucoma",
  page5headerPara:
    "Monitoring early signs and the progression of Glaucoma is now possible without leaving your home.",
  page5headerPara2:
    " Introducing our clinically tested and peer reviewed SPARCs Test, developed under the direction of world-renowned opthamologist Dr. George Spaeth.",
  page5headerBtnBefore: "First Month of Medication for Only $1",
  page5headerBtn: "Start Free Online Visit",
};

const page5Medication = {
  medicationsHead: "With Visionology medications:",
  MedicationArray: [
    {
      Noprior: "Formulated by our own FDA approved Pharmacy",
      NopriorImg: "https://uat.visionology.com/images/home/group-48.png",
    },
    {
      Noprior: "No preservatives in our ingredients",
      NopriorImg: "https://uat.visionology.com/images/home/group-50.png",
    },
    {
      Noprior: "Prescriptions reviewed by licensed physicians",
      NopriorImg: "https://uat.visionology.com/images/home/group-52.png",
    },
  ],
};

const mediBtn = {
  MedicationBtn: "Learn More",
};

const FDASec = {
  FDASecHead: "About Glaucoma",
  FDASecArray: [
    {
      AccordionBtn: "What is Glaucoma",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These ",
    },
    {
      AccordionBtn: "Are there different types of Glaucoma",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These ",
    },
    {
      AccordionBtn: "What are early and late signs of Glaucoma?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
    {
      AccordionBtn: "Is there a cure for Glaucoma?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
    {
      AccordionBtn: "Is Glaucoma hereditary?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
  ],
};

const FDASec2 = {
  FDASec2Head: "Preventative Glaucoma Care",
  FDASec2Para:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance. With medication prices ranging upwards of $300 and no insurance coverage, many may choose to put off treatment. Klarity-C Drops®…",
  FDASec2List1: "Free Online Visit",
  FDASec2List2: "FDA-Registered Pharmacy",
  FDASec2List3: "Unlimited Physician Follow Up",
  FDASec2Img: "https://place-hold.it/580x580",
};

const FDASec3 = {
  FDASec3Head: "SPARCS Test",
  FDASec3Para: "Spaeth/Richman Contrast Sensitivy Test",
  FDASec3Para2:
    "Developed by Glaucoma specialist and world-renowed Opthamologist, Dr. George L. Spaeth.",

  FDASec3Img: "https://place-hold.it/580x580",
};

const HowSParcs = {
  HowSParcsHead: " How SPARCS Works",
  HowSParcsSubHead: "  Helps Detect Early On-set of Ocular Diseases",
  HowSParcsPara:
    "Klarity is an eye lubricant that keeps your eye moist, helps protect the eye from injury and infection, and decreases the symptoms of dry eyes such as burning, itching and feeliing.",
  HowSParcsSubHead2: "Tests Ability to Perceive Contrast",
  HowSParcsPara2:
    " Using cyclosporine, Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  HowSParcsSubHead3: " Preventative Tool for Healthy Eyes",
  HowSParcsPara3:
    " Klarity-C is produced in small batches, meaning no preservatives are required or used in its formulation. This ensures freshness of product and minimal irritation for severe dry eye cases.",
  HowSParcsBtn: "Start Free Online Visit",
  HowSParcsbtn2: "Learn More",
};

const page5BlueBox = {
  page5BlueBoxHead: "How It Works",
  page5BlueBoxArray: [
    {
      justImgText: "1",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      justImgText: "2",
      just3Text: "Get physician approved prescription",
      just3Para:
        " After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
    },
    {
      justImgText: "3",
      just3Text: "Recieve your order at your doorstep",
      just3Para:
        "If appropriate, your provider can prescribe treatment and we’ll ship it right to your door.",
    },
  ],
};

const smallFaq = {
  smallFaqHead: "Frequently Asked Questions",
  smallFaqPara:
    "For more information about dry eye disease, Visionology, ImprimusRX and more, visit our FAQ page. To speak to a care representative, give us a call at ",
  smallFaqSpan: "(888) 616-6161",
  smallFaqSpan2: "hello@visionology.com",
  smallFaqBtn: "Start Free Online Visit",
  FAQArray: [
    {
      AccordionBtn: " How do I find the right information faster?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "How do I start the online intake form?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "What are estimated shipping times?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
  ],
};

const Just3Step = {
  justThreeHead: "How It Works",
  justThreeHeadArray: [
    {
      stepCss: "just3BoxImg1",
      justImgText: "1",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg2",
      justImgText: "2",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg3",
      justImgText3: "3",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
  ],
};

const CommonEyes = {
  CommonEyeHead: "Common Eye Symptoms",
  CommonEyeSubHead: "Vision Loss",
  CommonEyeBtn: "Learn More",
};

export {
  page5Header,
  page5Medication,
  mediBtn,
  FDASec,
  FDASec2,
  FDASec3,
  HowSParcs,
  page5BlueBox,
  smallFaq,
  CommonEyes,
  Just3Step,
};
