import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Faqs.css";

const Faqs = (props) => {
  const { FAQHead, FAQArray } = props;
  return (
    <Fragment>
      {" "}
      {/* faq */}
      <Container fluid className="FAQ py-5">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="faqHead mb-5">{FAQHead}</h2>
            {/* accordian */}
            <div class="accordion FaqAccordion" id="accordionExample">
              {FAQArray.map((item, index) => {
                return (
                  <div key={index} class="accordion-item">
                    <h2 class="accordion-header" id={`headingOne${index}`}>
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        <span className="ABtn_Txt">{item.AccordionBtn}</span>
                        <span className="AccordionIcon"></span>
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      class="accordion-collapse collapse"
                      aria-labelledby={`headingOne${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        {item.AccordionDescription}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* accordian end */}
          </Col>
        </Row>
      </Container>
      {/* faq end */}
    </Fragment>
  );
};

export default Faqs;
