import React, { Fragment, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueBox from "../HomePage/blueBox/BlueBox";
import OwlCarousel from "react-owl-carousel";
import FaqSmall from "../page5/faqSmall/FaqSmall";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { page6Header, page6BlueBox, HowSParcs, smallFaq, Over } from "./data";
import "./Page10.css";
const Page9 = () => {
  const [Value, setValue] = useState("Robert");
  const [Value1, setValue1] = useState("Broadbent");
  const [Email, setEmail] = useState("rrbroadbent@gmail.com");
  const [Password, setPassword] = useState("••••••••••");
  const OverSlider = {
    loop: true,
    margin: 18,
    items: 2,
    dots: true,
    nav: true,
    navSpeed: 1000,
    navText: [
      `<i class="fa fa-angle-left" aria-hidden="true"></i>`,
      `<i class="fa fa-angle-right" aria-hidden="true"></i>`,
    ],
    responsiveClass: true,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        margin: 10,
        nav: false,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  return (
    <Fragment>
      {/* dry eye header */}
      <Container fluid className="">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={6}>
                <h1 className="DryEyeHead ">{page6Header.head6}</h1>
                <p className="DryEyePara my-5">{page6Header.para6}</p>
                <ul className="dryEyeHeaderList">
                  <li className="headList1">{page6Header.List1}</li>
                  <li className="headList2">{page6Header.List2}</li>
                  <li className="headList3">{page6Header.List3}</li>
                </ul>
                <span className="mt-2 d-inline-block">
                  <button className="DryEyeHeaderBtn mt-4">
                    {page6Header.para6Btn}
                  </button>
                </span>
              </Col>
              <Col md={6}>
                <div>
                  <img
                    src="https://via.placeholder.com/580x580"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* dry eye header end */}
      <Container fluid className="howItworks5Bg pt-3">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <BlueBox Bluebox={page6BlueBox.page6BlueBoxArray[0]} />
              <BlueBox Bluebox={page6BlueBox.page6BlueBoxArray[1]} />
              <BlueBox Bluebox={page6BlueBox.page6BlueBoxArray[2]} />
            </Row>
          </Col>
        </Row>
      </Container>
      {/* ?End Eye header */}
      {/* SparcsWork */}
      <Container fluid className="SparcsWork SparcWork py-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={12} className="mx-auto">
                <h1 className="BannerW3s_Heading text-center mb-5">
                  {HowSParcs.HowSParcsHead}
                </h1>
              </Col>
              <Col md={6} className=" ">
                <div className="SparcsWorkContent">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcs.HowSParcsSubHead}
                  </h1>
                  <p className="me-md-4 mt-4 TxtContent">
                    {HowSParcs.HowSParcsPara}
                  </p>
                </div>
                <div className="SparcsWorkContent mt-5">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcs.HowSParcsSubHead2}
                  </h1>
                  <p className="me-md-4 mt-4 TxtContent">
                    {HowSParcs.HowSParcsPara2}
                  </p>
                </div>
                <div className="SparcsWorkContent mt-5">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcs.HowSParcsSubHead3}
                  </h1>
                  <p className="me-md-4 mt-4 TxtContent">
                    {HowSParcs.HowSParcsPara3}
                  </p>
                </div>
              </Col>
              <Col md={6} className="">
                <div className="SparcsWorkCImg"></div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-center my-5 pt-5">
                  <button className="HeroBtn mt-0 me-4">
                    {HowSParcs.HowSParcsBtn}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End  SparcsWork */}
      {/* Sign Up */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={12}>
                <h1 className="Vision">Sign Up Form</h1>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={4} className="">
                <div className="SingUpBox mb-5">
                  <div className="SingupNumber">
                    <span>1</span>
                  </div>
                  <p className="BoxDecript">
                    Complete Your Patient Intake Form In A HIPAA Compliant
                    Portal
                  </p>
                </div>
              </Col>
              <Col md={4} className="">
                <div className="SingUpBox mb-5">
                  <div className="SingupNumber">
                    <span>2</span>
                  </div>
                  <p className="BoxDecript">
                    Connect with A Licensed Specialist Within 24 Hours
                  </p>
                </div>
              </Col>
              <Col md={4} className="">
                <div className="SingUpBox mb-5">
                  <div className="SingupNumber">
                    <span>3</span>
                  </div>
                  <p className="BoxDecript">
                    Get Your Prescription Filled For Only $1 For Your First
                    Month
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End Sign Up */}
      {/* Join Netword */}
      <Container fluid className="JoinNetwork">
        <Row className="">
          <Col xs={11} className="max-width mx-auto py-sm-5">
            <Row>
              <Col md={12}>
                <h1 className="Vision">Join Our Network of Doctors</h1>
                <p className="JoinNetwordDescript">
                  Complete the introductory form below and you’ll hear back from
                  the Visionology team with next steps in the application
                  process!
                </p>
              </Col>
            </Row>
            <Row className="mt-sm-5 pt-5">
              <Col md={9} className="mx-auto">
                <Row>
                  <Col md={6}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">First Name</span>
                      <input
                        type="text"
                        value={Value}
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                        className="JNInput"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">Last Name</span>
                      <input
                        type="text"
                        value={Value1}
                        onChange={(e) => {
                          setValue1(e.target.value);
                        }}
                        className="JNInput"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">Sate</span>
                      <select name="" className="JNInput" id="">
                        <option value="Oregon">Oregon</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Oregon">Oregon</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">Specialization</span>
                      <select name="" className="JNInput" id="">
                        <option value="Oregon">Ophthalmologist</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Oregon">Oregon</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">Password</span>
                      <input
                        type="email"
                        value={Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="JNInput"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="JNinputWrapper mb-5">
                      <span className="JNTxt">Password</span>
                      <input
                        type="password"
                        value={Password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className="JNInput"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="text-center mb-5">
                      <button className="HeroBtn ">Create Account</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End Join Netword */}

      {/* faq */}
      <Container fluid className="FQuestion py-sm-5">
        <FaqSmall {...smallFaq} />
      </Container>
      {/* faq end */}

      {/* over 10000 */}
      <Container fluid className="">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="over10000Head text-black my-5">
              {Over.over10000Head}
            </h2>
            {/* carosel */}
            <OwlCarousel className="owl-theme OverSlider" {...OverSlider}>
              {Over.OverItem.map((item, index) => {
                return (
                  <div key={index} className="item ">
                    <Col md={12}>
                      <div className="overCarosuelBox CurrentItem">
                        <img
                          className="img-fluid mb-4 overBoxImg"
                          src={item.overBoxImg}
                          alt=""
                        />
                        <h5 className="overBoxHead">{item.overBoxHead}</h5>
                        <p className="overBoxPara">{item.overBoxPara}</p>
                        <button className="overBoxBtn mb-4">
                          {item.overBoxBtn}
                        </button>
                        <hr />
                        <p className="overBoxPara mb-0">{item.Duke}</p>
                      </div>
                    </Col>
                  </div>
                );
              })}
            </OwlCarousel>
            ;{/* carosel end */}
          </Col>
        </Row>
      </Container>
      {/* over 10000 end */}
    </Fragment>
  );
};

export default Page9;
