import React, { Fragment } from "react";
import {
  // Button,
  Col,
  Container,
  // FormControl,
  // InputGroup,
  Row,
} from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <Fragment>
      {/* footer */}
      <Container fluid className="FooterBG">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <div className="mb-5">
              <img src="https://uat.visionology.com/images/logo-icon.png" className="me-3" alt="" />{" "}
              <span className="FAC me-3">
                {" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>{" "}
              <a className="FNavigation" href="/">
                Home
              </a>
            </div>
            <Row>
              <Col xs={6} md={3} lg={2} className="mb-4">
                <h6 className="FooterLinkHead">How It Works</h6>
              </Col>
              <Col xs={6} md={3} lg={2} className="mb-4">
                <h6 className="FooterLinkHead">About Us</h6>
                <ul className="FooterLinks">
                  <li>
                    <a href="/">FAQs</a>
                  </li>
                  <li>
                    <a href="/">Our Pharmacy</a>
                  </li>
                  <li>
                    <a href="/">Our Doctors</a>
                  </li>
                  <li>
                    <a href="/">Medical Board</a>
                  </li>
                </ul>
              </Col>
              <Col xs={6} md={3} lg={2} className="mb-4">
                <h6 className="FooterLinkHead">Treatments</h6>
                <ul className="FooterLinks">
                  <li>
                    <a href="/">Dry Disease</a>
                  </li>
                  <li>
                    <a href="/">Glaucoma</a>
                  </li>
                  <li>
                    <a href="/">Red Eyes</a>
                  </li>
                  <li>
                    <a href="/">Dry eyes</a>
                  </li>
                  <li>
                    <a href="/">Inflammation</a>
                  </li>
                </ul>
              </Col>
              <Col xs={6} md={3} lg={2} className="mb-4">
                <h6 className="FooterLinkHead">Blog</h6>
              </Col>
              <Col lg={4}>
                {/* <InputGroup className="mb-3 FooterInputGroup">
                  <FormControl
                    placeholder="Email Address"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="footerFormControl"
                  />
                  <Button
                    className="footerBtn"
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    Submit
                  </Button>
                </InputGroup> */}
                {/* <p className="footerNo">123.456.7890</p> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* footerend */}
    </Fragment>
  );
};

export default Footer;
