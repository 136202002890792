import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import CardImg from "../../../Asset/CardImg.png";
import "./Step14.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step14 = ({ nextStep, prevStep }) => {
  return (
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div className="MainWrappers">
                <p class="TCDescript text-center mt-4">
                  You’re one click away.
                </p>
                {/* <!-- Lock box --> */}
                <div class="LockBox">
                  <div class="LoclImg">
                    <svg
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
                        fill="#04C600"
                      />
                    </svg>
                  </div>
                  <p class="LockTxt">
                    You’ll be charged $1 for your first 30-day supply, then $69
                    every month thereafter.
                  </p>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <input
                        class="form-control form-control-lg mt-3 searchbar-"
                        type="text"
                        placeholder="Name on card"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        class="form-control form-control-lg mt-3 searchbar-"
                        type="text"
                        placeholder="Last name on card"
                      />
                    </div>
                    <div class="col-md-12">
                      <input
                        class="form-control form-control-lg mt-3 searchbar-"
                        type="text"
                        placeholder="Credit card number"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        class="form-control form-control-lg mt-3 searchbar-"
                        type="text"
                        id="date"
                        placeholder="Expiration (mm/yy)"
                        onfocus="(this.type='date')"
                        onblur="(this.type='text')"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        class="form-control form-control-lg mt-3 searchbar-"
                        type="text"
                        placeholder="Billing Zip Code *"
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- Lock Image --> */}
                <div class="LockImage">
                  <a href="/">
                    <img src={CardImg} class="img-fluid mt-4" alt="" />
                  </a>
                </div>
                <p class="LockFTxt mt-4">
                  Cancel Anytime.
                  <br />
                  100% Money-back guarantee.
                </p>
              </div>
              {/* End Body Content */}
              <FooterBtn className="mt-5" Next={nextStep} Back={prevStep} />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step14;
