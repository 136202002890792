import React, { Fragment } from "react";
import "./FaqSmall.css";
import { Col, Container, Row } from "react-bootstrap";

const FaqSmall = (props) => {
  const {
    smallFaqHead,
    smallFaqPara,
    smallFaqSpan,
    smallFaqSpan2,
    smallFaqBtn,
    FAQArray,
  } = props;
  return (
    <Fragment>
      {/* FrequentlyQuestion */}
      <Row className="pt-5">
        <Col xs={11} className="mx-auto max-width ">
          <Row className="">
            <Col md={6} className=" ">
              <div className="FQContent">
                <h1 className="BannerW3s_Heading">{smallFaqHead}</h1>
                <p className="BannerSub_Description FQCTxt mt-4">
                  {smallFaqPara}
                  <span className="mailTxt">{smallFaqSpan}</span> or email us at
                  <span className="mailTxt">{smallFaqSpan2}</span>
                </p>
              </div>
            </Col>
            <Col md={6} className="">
              <div
                class="accordion FaqAccordion FQAccordion"
                id="accordionExample"
              >
                {FAQArray.map((item, index) => {
                  return (
                    <div key={index} class="accordion-item">
                      <h2 class="accordion-header" id={`headingOne${index}`}>
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                        >
                          {item.AccordionBtn}
                          <span className="AccordionIcon"></span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`headingOne${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          {item.AccordionDescription}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col md={12}>
              <div className="d-flex justify-content-center my-5 pt-5">
                <button className="HeroBtn mt-0 me-4">{smallFaqBtn}</button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* End FrequentlyQuestion */}
    </Fragment>
  );
};

export default FaqSmall;
