import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import "./FDASection1.css";
const FDASection1 = (props) => {
  const { FDASecHead, FDASecArray } = props;
  return (
    <Fragment>
      {/* FDA Section 1 */}
      {/* <Container fluid className="FdaSectionBg"> */}
      <Row>
        <Col xs={11} className="max-width mx-auto py-5">
          <Row className="py-5">
            <Col lg={6} className="text-center">
              <img
                className="img-fluid"
                src="https://uat.visionology.com/images/home/dry-eye-disease-banner.png"
                alt=""
              />
            </Col>
            <Col lg={6} className="mt-lg-0 mt-5">
              <div className="FDASection1">
                <h2 className="FdaHead">{FDASecHead}</h2>
                {/* Accordion */}
                <div class="accordion FDAAccordionw3" id="accordionExample">
                  {FDASecArray.map((item, index) => {
                    return (
                      <div key={index} class="accordion-item">
                        <h2 class="accordion-header" id={`headingOne${index}`}>
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            {item.AccordionBtn}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          class="accordion-collapse collapse"
                          aria-labelledby={`headingOne${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {item.AccordionDescription}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* End Accordion */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </Container> */}
      {/* FDA Section 2 */}
    </Fragment>
  );
};

export default FDASection1;
