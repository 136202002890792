import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Medications.css";

const Medications = (props) => {
  const { medicationsHead, MedicationArray } = props;
  return (
    <Fragment>
      {/* medications */}
      <Container fluid className="medicationBGWrapper">
        <Row className="medicationBG">
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="medicationsHead">{medicationsHead}</h2>
            <Row className="justify-content-center">
              {MedicationArray.map((item, index) => {
                return (
                  <Col key={index} md={4} className="medicationBoxWrapper mb-4">
                    <div className="medicationBox">
                      <img className="img-fluid" src={item.NopriorImg} alt="" />
                      <p className="medicationBoxText">{item.Noprior}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>

      {/* medications end */}
    </Fragment>
  );
};

export default Medications;
