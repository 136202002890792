const page6Header = {
  head6: "A convenient way to relieve Dry Eye Disease symptoms.",
  para6:
    " Relieve Dry Eye Disease symptoms with Klarity-C Drops® delivered directly to your door (if prescribed).",
  List1: "Free Online Visit",
  List2: "FDA-Registered Pharmacy",
  List3: "Unlimited Physician Follow Up",
  page6BtnBefore: "First Month of Medication for Only $1",
  para6Btn: "Start Free Online Visit",
};

const FDASec = {
  FDASecHead: "About Dry Eye Disease",
  FDASecArray: [
    {
      AccordionBtn: "What causes Dry Eye Disease?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These ",
    },
    {
      AccordionBtn: "Are there different types of Dry Eyes?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These ",
    },
    {
      AccordionBtn: "What are common Dry Eye Disease symptoms?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
    {
      AccordionBtn: "Is there a cure for Dry Eye Disease?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
    {
      AccordionBtn: "Can Dry Eye Disease be left untreated?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the .",
    },
  ],
};

const FDASec2 = {
  FDASec2Head: "Preventative Glaucoma Care",
  FDASec2Para:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance. With medication prices ranging upwards of $300 and no insurance coverage, many may choose to put off treatment. Klarity-C Drops®…",
  FDASec2List1: "Free Online Visit",
  FDASec2List2: "FDA-Registered Pharmacy",
  FDASec2List3: "Unlimited Physician Follow Up",
  FDASec2Img: "https://uat.visionology.com/images/Conditions/DryEyeDisease/Dry-Eye-Disease.jpeg",
};

const FDASec3 = {
  FDASec3Head: "Treatment: Klarity-C Drops®",
  FDASec3Para: " (Cyclosporine 0.1% ophthalmic emulsion PF)*",
  FDASec3Para2:
    "Manufactured in our FDA Registered Pharmacy, Klarity-C has relieved dry eye symptoms for more than 10,000 patients.",
  FDASec3Img: "https://uat.visionology.com/images/Conditions/DryEyeDisease/Klairty-C1.png",
};

const HowSParcs = {
  HowSParcsHead: " How Klarity-C Eye Drops Can Help",
  HowSParcsSubHead: "Lubricates The Eye",
  HowSParcsPara:
    "Klarity is an eye lubricant that keeps your eye moist, helps protect the eye from injury and infection, and decreases the symptoms of dry eyes such as burning, itching and feeling that something in the eye.",
  HowSParcsSubHead2: "Increases Natural Tear Production",
  HowSParcsPara2:
    "Using cyclosporine, Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  HowSParcsSubHead3: "Preservative Free in Multidose Bottles",
  HowSParcsPara3:
    "Klarity-C is produced in small batches, meaning no preservatives are required or used in its formulation. This ensures freshness of product and minimal irritation for severe dry eye cases. ",
  HowSParcsBtn: "Start Free Online Visit",
  HowSParcsbtn2: "Learn More",
};

const page6BlueBox = {
  page6BlueBoxHead: "How It Works",
  page6BlueBoxArray: [
    {
      justImgText: "1",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      justImgText: "2",
      just3Text: "Get physician approved prescription",
      just3Para:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
    },
    {
      justImgText: "3",
      just3Text: "Recieve your order at your doorstep",
      just3Para:
        "If appropriate, your provider can prescribe treatment and we’ll ship it right to your door.",
    },
  ],
};

const smallFaq = {
  smallFaqHead: "Frequently Asked Questions",
  smallFaqPara:
    "For more information about dry eye disease, Visionology, ImprimusRX and more, visit our FAQ page. To speak to a care representative, give us a call at ",
  smallFaqSpan: "(888) 616-6161",
  smallFaqSpan2: "hello@visionology.com",
  smallFaqBtn: "Start Free Online Visit",
  FAQArray: [
    {
      AccordionBtn: "How do I know if Klarity-C is working?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the ",
    },
    {
      AccordionBtn: "Where do you make your medication?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, u",
    },
    {
      AccordionBtn: "What are the potential side effects?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the sh",
    },
    {
      AccordionBtn: "What will I be charged after the first month?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the sh",
    },
  ],
};

const Just3Step = {
  justThreeHead: "How It Works",
  justThreeHeadArray: [
    {
      stepCss: "just3BoxImg1",
      justImgText: "1",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg2",
      justImgText: "2",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg3",
      justImgText3: "3",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
  ],
};

const CommonEye = {
  CommonEyeHead: "Common Eye Symptoms",
   DryEyes: [
    { image:"commonEyeBox1",  DryHead: "Dry Eyes", DryBtn: "Learn More" },
    { image:"commonEyeBox2",  DryHead: "Inflammation", DryBtn: "Learn More" },
    { image:"commonEyeBox3",  DryHead: "Stinging or Burning", DryBtn: "Learn More" },
    { image:"commonEyeBox4",  DryHead: "Red Eyes", DryBtn: "Learn More" },
    { image:"commonEyeBox5",  DryHead: "Blurry Vision", DryBtn: "Learn More" },
    { image:"commonEyeBox6",  DryHead: "Light Sensitivity", DryBtn: "Learn More" },
  ],
};

// const CommonEyes = {
//   CommonEyeHead: "Common Eye Symptoms",
//   commonEyeArray: [
//     {
//       CommonEyeSubHead: "Vision Loss",
//       CommonEyeBtn: "Learn More",
//     },
//     {
//       CommonEyeSubHead: "Inflammation",
//       CommonEyeBtn: "Learn More",
//     },
//     {
//       CommonEyeSubHead: "Stinging or Burning",
//       CommonEyeBtn: "Learn More",
//     },
//     {
//       CommonEyeSubHead: "Red Eyes",
//       CommonEyeBtn: "Learn More",
//     },
//     {
//       CommonEyeSubHead: "Blurry Vision",
//       CommonEyeBtn: "Learn More",
//     },
//     {
//       CommonEyeSubHead: "Light Sensitivity",
//       CommonEyeBtn: "Learn More",
//     },
//   ],
// };

export {
  page6Header,
  FDASec,
  FDASec2,
  FDASec3,
  HowSParcs,
  page6BlueBox,
  smallFaq,
  Just3Step,
  // CommonEyes,
  CommonEye,
};
