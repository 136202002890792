import React, {useEffect} from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step7.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step7 = ({ nextStep, prevStep, handleFormData, updateInputData, values }) => {
  const [YesActive, setYesActive] = React.useState(values.characteristics);
  const YesActiveHandler = (key) => {
    switch (key) {
      case "Select":
        setYesActive((prevState) => ({
          ...prevState,
          Select: true,
        }));
        break;
      case "smoke":
        setYesActive((prevState) => ({
          ...prevState,
          smoke: true,
        }));
        break;
      case "contact":
        setYesActive((prevState) => ({
          ...prevState,
          contact: true,
        }));
        break;
      default:
    }
  };
  const NoActiveHandler = (key) => {
    switch (key) {
      case "Select":
        setYesActive((prevState) => ({
          ...prevState,
          Select: false,
        }));
        break;
      case "smoke":
        setYesActive((prevState) => ({
          ...prevState,
          smoke: false,
        }));
        break;
      case "contact":
        setYesActive((prevState) => ({
          ...prevState,
          contact: false,
        }));
        break;
      default:
    }
  };
  useEffect(() => {
    updateInputData("characteristics",YesActive);
  });
  return (
    //    <!-- Desktop 3 Start -->
    <section id="desktop3" class="desktop3 py-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-11 max-width-q section-body_ mx-auto">
            <div class="row">
              <Headers {...headerData} />
              {/* <!-- body Content --> */}
              <div class="col-md-12 body-border_ PaddingX2rem">
                {/* <!-- topbar start --> */}
                <Progress />
                {/* <!-- topbar end -->
                {/* <!-- first box  --> */}
                <div class="row first-box_ mt-5 mb-3 mx-auto">
                  <div class="col-sm-6 my-2 align-self-center">
                    <div class="first-text_ text-center text-sm-start">
                      Select your sex
                    </div>
                  </div>
                  <div class="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first4-btn_ py-1 px-4 me-1 ${
                          YesActive.Select ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "Select")}
                      >
                        Female
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "Select")}
                        className={`btn first4-btn_ py-1 px-4 ms-1 ${
                          YesActive.Select ? null : "ActiveBtn"
                        }`}
                      >
                        Male
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- first box end --> */}

                {/* <!-- Second box  --> */}
                <div class="row first-box_ mb-3 mx-auto">
                  <div class="col-sm-6 my-2 align-self-center">
                    <div class="first-text_ text-center text-sm-start">
                      Do you smoke?
                    </div>
                  </div>
                  <div class="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.smoke ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "smoke")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "smoke")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.smoke ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- Second box end --> */}

                {/* <!-- third box  --> */}
                <div class="row first-box_ mb-3 mx-auto">
                  <div class="col-sm-7 my-2 align-self-center">
                    <div class="first-text_ text-center text-sm-start">
                      Do you wear contact lenses?
                    </div>
                  </div>
                  <div class="col-sm-5 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.contact ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "contact")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "contact")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.contact ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- third box end --> */}
                {/*  */}
                <FooterBtn
                  className="MarginTop20"
                  Next={nextStep}
                  Back={prevStep}
                />
                {/*  */}
              </div>
              {/* <!-- Footer start --> */}
              <Footers {...footerData} />
              {/* <!-- Footer End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Desktop 3 End --> */}
    </section>
  );
};

export default Step7;