import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueBox from "../HomePage/blueBox/BlueBox";
import Medications from "../HomePage/medications/Medications";
import SimpleBox from "./simpleBox/SimpleBox";
import HowSection from "./HowSection/HowSection";
import FaqSmall from "./faqSmall/FaqSmall";
import FDASection1 from "./FDASection1/FDASection1";
import "./FifthPage.css";
import HpJust3steps from "../HomePage/HpJust3Steps/HpJust3Steps";

import {
  page5Header,
  page5Medication,
  mediBtn,
  FDASec,
  FDASec2,
  FDASec3,
  HowSParcs,
  page5BlueBox,
  smallFaq,
  CommonEyes,
  Just3Step,
} from "./data5";
const Index = () => {
  return (
    <Fragment>
      {/* HeroSection */}
      <Container fluid className="TrackingBanner">
        <Row className="py-5 w-100">
          <Col xs={11} className="mx-auto max-width ">
            <Row>
              <Col lg={8} className="">
                <div className="bannerContent  mt-4">
                  <p className="BannerSub_Description mb-2">
                    {page5Header.page5headerSubhead}
                  </p>
                  <h1 className="BannerW3s_Heading mb-4">
                    {page5Header.page5headerhead}
                  </h1>
                  <p className="BannerSub_Description TBDescript mb-4">
                    {page5Header.page5headerPara}
                  </p>

                  <p className="BannerSub_Description TBDescript mb-4">
                    {page5Header.page5headerPara2}
                  </p>
                </div>
                <div className="TBDescriptBottom mb-4">
                  <span>{page5Header.page5headerBtnBefore}</span>
                  <button className="HeroBtn">
                    {page5Header.page5headerBtn}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* HeroSection end */}

      {/* medication */}
      <Medications {...page5Medication} />
      <Container fluid className="medicationBackGround">
        <Row>
          <Col xs={11} className="max-width pb-5 mx-auto">
            <div className="text-center">
              <button className="HeroBtn">{mediBtn.MedicationBtn}</button>
            </div>
          </Col>
        </Row>
      </Container>
      {/* medicationend */}

      {/* FDA Section 1 */}
      <Container fluid className="FdaSectionBg">
        <FDASection1 {...FDASec} />
      </Container>

      {/* FDA Section 2 */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-lg-5">
            <Row className="py-lg-5">
              <Col lg={6} className="order-lg-0 order-1 mt-lg-0 mt-4 ">
                <div className="FDASection1 me-auto">
                  <h2 className="FdaHead">{FDASec2.FDASec2Head}</h2>
                  <p className="FdaPara">{FDASec2.FDASec2Para}</p>
                  <ul className="FdaList">
                    <li className="FdafirstList">{FDASec2.FDASec2List1}</li>
                    <li className="FdaSecondList">{FDASec2.FDASec2List2}</li>
                    <li className="FdaThirdList">{FDASec2.FDASec2List3}</li>
                  </ul>
                </div>
              </Col>
              <Col lg={6} className="text-center order-lg-1 order-0">
                <img className="img-fluid" src={FDASec2.FDASec2Img} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section 3 */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDASec3.FDASec3Img} alt="" />
              </Col>
              <Col lg={6}>
                <div className="FDASection1 ">
                  <h2 className="FdaHead py-5 mt-5">{FDASec3.FDASec3Head}</h2>
                  <p className="FdaPara">{FDASec3.FDASec3Para}</p>
                  <p className="FdaPara">{FDASec3.FDASec3Para2}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* visonologySkip */}
      <HowSection {...HowSParcs} />
      {/* visonologySkip end */}

      {/* just 3steps */}
      <HpJust3steps {...Just3Step} />
      {/* just 3stepsend */}


      

      {/* faq */}
      <Container fluid className="FrequentlyQuestion py-sm-5">
        <FaqSmall {...smallFaq} />
      </Container>
      {/* faq end */}

      {/* Common Eyes */}
      <Container fluid className="CommonEyes py-sm-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={12} className="mx-auto">
                <h1 className="BannerW3s_Heading text-center my-sm-5 pb-5">
                  {CommonEyes.CommonEyeHead}
                </h1>
              </Col>
              <SimpleBox {...CommonEyes} />
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End Common Eyes */}
    </Fragment>
  );
};

export default Index;
