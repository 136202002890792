const page7Header = {
  headerHead: "Dry Eyes",
  headerPImg: "https://place-hold.it/20x20",
  headerSmPara: "Medically Reviewed by",
  headerSmParaEnd: "December 21, 2020",
  headerSmParaStrong: "Whitney Seltman, OD.",
  headerPara:
    "Copy about Dry Eyes and how it can be an indicator of other symptoms. Copy about Dry Eyes and how it can be an indicator of other symptoms.",
  headerBtn: "Start Free Online Visit",
  headerSubHead: "Common Conditions",
  headerb1Head: "Dry Eye Disease",
  headerb2Head: "Rosacea",
  headerb3Head: "Pink Eye",
  headerb4Head: "Sjorgren’s Syndrome",
};

const FdaSec1 = {
  fdaHead: "What Causes Dry Eyes?",
  fdaPara:
    "Dry eye inflammation is any immune response that causes dry eye swelling in or around the eyes. If there’s inflammation in the tear or lacrimal glands that line your lids, cornea (your eye's clear outer layer), or conjunctiva (outermost layers of your eyes), your body may not make enough tears or your tears may not contain the right mix of water, oils, and salts.",
  fdaImg: "https://place-hold.it/580x580",
};

const FdaSec2 = {
  fdaHead: "What is Dry Eye Disease?",
  fdaPara:
    "An ocular surface disorder, dry eye disease happens when your eyes don’t produce enough tears or if they make the wrong kind of tears. The medical name for dry eye disease is keratoconjunctivitis sicca.",
  fdaImg: "https://place-hold.it/580x580",
};

const FdaSec3 = {
  fdaHead: "Treatment: Klarity-C Drops®",
  fdaPara: "(Cyclosporine 0.1% ophthalmic emulsion PF)*",
  fdaPara2:
    " Manufactured in our FDA Registered Pharmacy, Klarity-C has relieved dry eye symptoms for more than 10,000 patients.",
  fdaImg: "https://place-hold.it/580x580",
};

const Aqueous = {
  AqueousHead: "Aqueous Tear Dificiency",
  AqueousPara:
    "Dry eye inflammation is any immune response that causes dry eye swelling in or around the eyes. If there’s inflammation in the tear or lacrimal glands that line your lids, cornea (your eye's clear outer layer), or conjunctiva (outermost layers of your eyes), your body may not make enough tears or your tears may not contain the right mix of water, oils, and salts.",
  AqueousPara2:
    "An ocular surface disorder, dry eye disease happens when your eyes don’t produce enough tears or if they make the wrong kind of tears. The medical name for dry eye disease is keratoconjunctivitis sicca.",
  AqueousImg: "https://place-hold.it/298x298",
};

const Aqueous2 = {
  AqueousHead: "Aqueous Tear Dificiency",
  AqueousPara:
    "Dry eye inflammation is any immune response that causes dry eye swelling in or around the eyes. If there’s inflammation in the tear or lacrimal glands that line your lids, cornea (your eye's clear outer layer), or conjunctiva (outermost layers of your eyes), your body may not make enough tears or your tears may not contain the right mix of water, oils, and salts.",
  AqueousPara2:
    "An ocular surface disorder, dry eye disease happens when your eyes don’t produce enough tears or if they make the wrong kind of tears. The medical name for dry eye disease is keratoconjunctivitis sicca.",
  AqueousImg: "https://place-hold.it/298x298",
};
const Medication = {
  medicationsHead: "With Visionology medications:",
  MedicationArray: [
    {
      Noprior: "Formulated by our own FDA approved Pharmacy",
      NopriorImg: "https://place-hold.it/85x73",
    },
    {
      Noprior: "No preservatives in our ingredients",
      NopriorImg: "https://place-hold.it/85x73",
    },
    {
      Noprior: "Prescriptions reviewed by licensed physicians",
      NopriorImg: "https://place-hold.it/85x73",
    },
  ],
};
const StartYourBtn = {
  StartYourBtn: "Start free Online Visit",
  StartYourBtn2Before: "Speak to a Representative:",
  StartYourBtn2: "123-456-7890",
};
export {
  page7Header,
  FdaSec1,
  FdaSec2,
  FdaSec3,
  Aqueous,
  Medication,
  StartYourBtn,
};
