import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import BlueBox from "../HomePage/blueBox/BlueBox";
import Faqs from "../HomePage/Faqs/Faqs";
import HeroSectionBreak from "../HomePage/HeroSectionBreak/HeroSectionBreak";
import FDAApprvoed from "../page2/FDAApproved";
import Medications from "../HomePage/medications/Medications";
import HpJust3steps from "../HomePage/HpJust3Steps/HpJust3Steps";
import "./HowItWorks.css";
import {
  page3Header,
  Page3HeroSectionbreak,
  QuestionSec,
  QuestionSec2,
  QuestionSec3,
  Page3FDAApproved,
  // FdaBoxes,
  page3Quality,
  Page3FAQ,
  StartYour,
  StartYourBtn,
  Just3Step,
} from "./data3";
import MedicationBtn from "../HomePage/medicationBtn/MedicationBtn";

const HowItWorks = () => {
  return (
    <Fragment>
      {/* header */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <h1 className="headerHead">{page3Header.headerHead}</h1>
            <p className="headerPara">{page3Header.headerPara}</p>
          </Col>
        </Row>
      </Container>
      {/* header end */}

      {/* heroSectionBreak */}
      <HeroSectionBreak HeroSectionbreak={Page3HeroSectionbreak} />
      {/* heroSectionBreak end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead">{QuestionSec.QuestionSecHead}</h2>
                <p className="FdaPara">{QuestionSec.QuestionSecPara}</p>
                <ul className="FdaList">
                  <li className="FdafirstList">
                    {QuestionSec.QuestionSecList1}
                  </li>
                  <li className="FdaSecondList">
                    {QuestionSec.QuestionSecList2}
                  </li>
                </ul>
              </Col>
              <Col lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={QuestionSec.QuestionSecImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section2 */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={QuestionSec2.QuestionSec2Img}
                  alt=""
                />
              </Col>
              <Col lg={6}>
                <h2 className="FdaHead">{QuestionSec2.QuestionSec2Head}</h2>
                <p className="FdaPara">{QuestionSec2.QuestionSec2Para}</p>
                <p className="FdaPara">{QuestionSec2.QuestionSec2Para2}</p>
                <ul className="FdaList">
                  <li className="FdafirstList">
                    {QuestionSec2.QuestionSec2List1}
                  </li>
                  <li className="FdaSecondList">
                    {QuestionSec2.QuestionSec2List2}
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section2 end */}

      {/* FDA Section3 */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead">{QuestionSec3.QuestionSec3Head}</h2>
                <p className="FdaPara">{QuestionSec3.QuestionSec3Para}</p>
                <p className="FdaPara">{QuestionSec3.QuestionSec3Para2}</p>
                <ul className="FdaList">
                  <li className="FdafirstList">
                    {QuestionSec3.QuestionSec3List1}
                  </li>
                  <li className="FdaSecondList">
                    {QuestionSec3.QuestionSec3List2}
                  </li>
                </ul>
                <p className="FdaSubPara">{QuestionSec3.QuestionSec3SubPara}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={QuestionSec3.QuestionSec3Img}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="text-md-end text-center">
                <a href="https://uatquiz.visionology.com" className="HeroBtn2 text-decoration-none" target={'_blank'} rel="noreferrer">{QuestionSec3.QuestionSec3Btn}</a>
              </Col>
              <Col md={6} className="text-md-start text-center">
                <div className="M_BtnBeforeWrapper">
                  <p className="medicationBtnBefore2">
                    {QuestionSec3.QuestionSec3Btn2Before}
                  </p>
                  <a className="medicationPhoneBTN2" href="tel:+18446372020">
                    <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    {QuestionSec3.QuestionSec3Btn2}
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section3 end */}


      

      
      {/* how blue boxes */}
      <Container fluid className="px-0">
        <FDAApprvoed {...Page3FDAApproved} />
      </Container>
      {/* <Container fluid className="mb-5">
        <Row>
          <Col xs={11} className="max-width mx-auto">
            <Row>
              <BlueBox Bluebox={FdaBoxes.page3BlueBox[0]} />
              <BlueBox Bluebox={FdaBoxes.page3BlueBox[1]} />
              <BlueBox Bluebox={FdaBoxes.page3BlueBox[2]} />
            </Row>
          </Col>
        </Row>
      </Container> */}
      {/* how blue boxes end */}


      {/* just 3steps */}
      <HpJust3steps {...Just3Step} />
      {/* just 3stepsend */}


      {/* high quality med */}
      <Container fluid className="highQualityBg">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={5} lg={6}>
                <h2 className="highQualityHead">
                  {page3Quality.page3QualityHead}
                </h2>
                <p className="highQualityPara">
                  {page3Quality.page3QualityPara}
                </p>
              </Col>
              <Col md={7} lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={page3Quality.page3QualityImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* high quality med end */}

      {/* Faqs */}
      <Faqs {...Page3FAQ} />
      {/* Faqs end */}

      {/* medications */}
      <Medications {...StartYour} />
      <MedicationBtn {...StartYourBtn} />
      {/* medications end */}
    </Fragment>
  );
};

export default HowItWorks;
