import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./Page11.css";
//----Static Pages-------
import Step1 from "../pages/Step1/Step1";
import Step2 from "../pages/Step2/Step2";
import Step3 from "../pages/Step3/Step3";
import Step4 from "../pages/Step4/Step4";
import Step5 from "../pages/Step5/Step5";
import Step6 from "../pages/Step6/Step6";
import Step7 from "../pages/Step7/Step7";
import Step8 from "../pages/Step8/Step8";
import Step9 from "../pages/Step9/Step9";
import Step10 from "../pages/Step10/Step10";
import Step11 from "../pages/Step11/Step11";
import Step12 from "../pages/Step12/Step12";
import Step13 from "../pages/Step13/Step13";
import Step14 from "../pages/Step14/Step14";
import Step15 from "../pages/Step15/Step15";

const Page11 = () => {
  
  //state for steps
  const [step, setstep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailID: "-",
    dryEyeRange: 0,
    dryEyeRange1: 0,
    dailyLifeEffectRange: 0,
    dailyLifeEffectRange1: 0,
    everBeenDiagnosed: {
      Dry: true,
      glaucoma: true,
      Cataracts: false,
      Retinal: true,
      conditions: false,
    },
    characteristics: {
      Select: true,
      smoke: true,
      contact: false,
    },
    takingAnyMedications: false,
    takingAnyMedicationsName: "",
    takingAnyAllergyMedications: false,
    takingAnyAllergyMedicationsName: "",
    dobDay: "DD",
    dobMonth: "MM",
    dobYear: "YY",
  });
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    // e.preventDefault();
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    // e.preventDefault();
    setstep(step - 1);
  };

  const updateInputData = (input,value) => {
    
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  useEffect(() => {
    if (step) {
      let activeWidth =
        step === 15 ? 100 : parseFloat(parseFloat(6.66) * parseFloat(step));
      $(".ProgressBar").css("width", `${activeWidth}%`);
      console.log(activeWidth);
    }
  });
  // javascript switch case to show different form in each step
  switch (step) {
    case 1:
      return (
        <Step1
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 2:
      return (
        <Step2
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 3:
      return (
        <Step3
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 4:
      return (
        <Step4
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 5:
      return (
        <Step5
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 6:
      return (
        <Step6
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 7:
      return (
        <Step7
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 8:
      return (
        <Step8
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 9:
      return (
        <Step9
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 10:
      return (
        <Step10
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 11:
      return (
        <Step11
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 12:
      return (
        <Step12
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 13:
      return (
        <Step13
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    case 14:
      return (
        <Step14
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
        />
      );
    case 15:
      return (
        <Step15
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          updateInputData={updateInputData}
          values={formData}
        />
      );
    default:
      return <div className="App"></div>;
  }
};

export default Page11;
