import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step9 = ({ nextStep, prevStep, handleFormData, updateInputData, values  }) => {

  const allergyList = [
    'No Known Drug Allergies',
    'Accupril',
    'Aciphex',
    'Actos',
    'Adalat CC',
    'Adderall',
    'Albuterol',
    'Alesse 28',
    'Allegra',
    'Allegra-D',
    'Allopurinol',
    'Alphagan',
    'Alprazolam',
    'Altace',
    'Amaryl',
    'Ambien',
    'Amitriptyline HCL',
    'Amoxicillin Trihydrate',
    'Amoxil',
    'Amphetamine',
    'Amyl nitrite',
    'Anabolic Steroids',
    'Aricept',
    'Atenolol',
    'Atenolol (Mylan)',
    'Atrovent',
    'Augmentin',
    'Avandia',
    'Avapro',
    'Azmacort',
    'Bactroban',
    'Baycol',
    'Balsam of Peru',
    'Biaxin',
    'Buckwheat',
    'Buspar',
    'Cardizem CD',
    'Cardura',
    'Carisoprodol',
    'Ceftin',
    'Cefzil',
    'Celebrex',
    'Celery',
    'Celexa',
    'Cephalexin',
    'Cipro',
    'Claritin',
    'Claritin Reditabs',
    'Claritin-D 12HR',
    'Claritin-D 24HR',
    'Climara',
    'Clonazepam',
    'Clonidine HCL',
    'Cocaine',
    'Combivent',
    'Coumadin',
    'Cozaar',
    'Cyclobenzaprine',
    'Depakote',
    'Detrol',
    'Diazepam',
    'Diflucan',
    'Dilantin',
    'Diovan',
    'Diovan HCT',
    'Effexor XR',
    'Egg',
    'Elocon',
    'Ery-Tab',
    'Evista',
    'Fish',
    'Flomax',
    'Flonase',
    'Flovent',
    'Folic Acid',
    'Fosamax',
    'Fruit',
    'Furosemide',
    'Garlic',
    'Gemfibrozil',
    'Glucophage',
    'Glucotrol XL',
    'Glyburide',
    'Hydrochlorothiazide',
    'Hydrocodone',
    'Hyzaar',
    'Ibuprofen',
    'Imitrex',
    'Isosorbide Mononitrate',
    'K-Dur',
    'Ketamine',
    'Klor-Con 10',
    'Lanoxin',
    'Lescol',
    'Levaquin',
    'Levothroid',
    'Levoxyl',
    'Lipitor',
    'Lo Ovral',
    'Loestrin FE',
    'Lorazepam',
    'Lotensin',
    'Lotrel',
    'Lotrisone',
    'Lysergic Acid Diethylamide',
    'Macrobid',
    'Maize',
    'Marijuana',
    'MDMA',
    'Medroxyprogesterone',
    'Mescaline',
    'Methamphetamine',
    'Methylphenidate',
    'Methylprednisolone',
    'Metoprolol Tartrate',
    'Miacalcin',
    'Milk',
    'Monopril',
    'Morphine',
    'Mustard',
    'Naproxen',
    'Naproxen-Sodium',
    'Nasonex',
    'Neurontin',
    'Nicotine',
    'Norvasc',
    'Oats',
    'Ortho-Cyclen',
    'Ortho-Tri-Cyclen',
    'Oxycodone',
    'OxyContin',
    'Paxil',
    'Peanut',
    'Penicillin VK',
    'Pepcid',
    'Phenergan',
    'Plavix',
    'Plendil',
    'Potassium chloride',
    'Poultry',
    'Pravachol',
    'Prednisone',
    'Premarin',
    'Prempro',
    'Prevacid',
    'Prilosec',
    'Prinivil',
    'Procardia-XL',
    'Promethazine',
    'Propoxyphene',
    'Proventil HFA',
    'Prozac',
    'Psilocybin',
    'Ranitidine',
    'Red meat',
    'Relafen',
    'Remeron',
    'Rice',
    'Risperdal',
    'Ritalin',
    'Rohypnol',
    'Roxicet',
    'Serevent',
    'Serzone',
    'Sesame',
    'Shellfish',
    'Singulair',
    'Soy',
    'Sulfites',
    'Synthroid',
    'Tamoxifen Citrate',
    'Tartrazine',
    'Temazepam',
    'Tiazac',
    'Tobradex',
    'Toprol XL',
    'Trazodone',
    'Tree nut',
    'Triamterene',
    'Trimox',
    'Triphasil',
    'Ultram',
    'Valtrex',
    'Vasotec',
    'Veetids',
    'Verapamil',
    'Viagra',
    'Vicoprofen',
    'Vioxx',
    'Warfarin Sodium',
    'Wellbutrin SR',
    'Wheat',
    'Xalatan',
    'Xenical',
    'Zestoretic',
    'Zestril',
    'Ziac',
    'Zithromax',
    'Zithromax Z-PAK',
    'Zocor',
    'Zoloft',
    'Zyprexa',
    'Zyrtec',
  ];
  
  return (
    //    <!-- Desktop 3 Start -->
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div class="fourth-text- py-3 text-center">
                <p class="">
                  Do you have any medication or other known allergies?
                  <br />
                  <br />
                </p>
                <p class="px-5">
                  If your allergy is not listed, you can type your allergy and
                  press enter.
                </p>
              </div>
              {/* <!-- body text --> */}
              {/* <!-- chekbox --> */}
              <div class="Dflex">
                <div class="form-check">
                  <input
                    class="form-check-input large-size mt-0"
                    type="checkbox"
                    value={values.takingAnyAllergyMedications ? "true" : "false" }
                    id="flexCheckDefault"
                    onChange={handleFormData("takingAnyAllergyMedications")}
                  />
                  <label class="form-check-label px-2" for="flexCheckDefault">
                    I'm not currently taking any medications.
                  </label>
                </div>
              </div>
              {/* <!-- chekbox --> */}
              {/* <!-- input box --> */}
              <input
                class="form-control form-control-lg mt-4 searchbar- mx-auto"
                type="text"
                placeholder="Select from the list or start typing..."
                value={values.takingAnyAllergyMedicationsName} onChange={handleFormData("takingAnyAllergyMedicationsName")}
              />
              {/* End Body Content */}
              <FooterBtn
                className="MarginTop20"
                Next={nextStep}
                Back={prevStep}
              />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step9;
