import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import Botel from "../../../Asset/Botel.png";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
import "./Step15.css";

const Step15 = ({ nextStep, prevStep }) => {
  return (
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div className="MainWrapper">
                <h6 class="BoltelHead text-center mt-4">
                  Your order for Klarity-C® eye drops is complete. We'll text
                  you when your doctor reviews and approves your exam.
                </h6>
                <div class="BotelImg mb-4">
                  <a href="/">
                    <img src={Botel} class="img-fluid mt-2" alt="" />
                  </a>
                </div>
                <div class="BotelBottom">
                  <p class="BotelBottomTxt">
                    <span>
                      If your doctor needs more information about your
                      condition, our care team will contact you promptly. You'll
                      receive a text when your order ships from the pharmacy.
                    </span>
                    <br />
                    <br />
                    <span> If you have any questions you can </span>
                    <br />
                    <br />
                    <span>
                      Call or text us at (844) 637-2020 Email at
                      info@visionology.com
                    </span>
                  </p>
                </div>
              </div>
              {/* End Body Content */}
              <FooterBtn className="mt-5" Back={prevStep} />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step15;
