import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueBox from "../HomePage/blueBox/BlueBox";
import FaqSmall from "../page5/faqSmall/FaqSmall";
import FDASection1 from "../page5/FDASection1/FDASection1";
import HowSection from "../page5/HowSection/HowSection";
import SimpleBox from "../page5/simpleBox/SimpleBox";
import HpJust3steps from "../HomePage/HpJust3Steps/HpJust3Steps";

import "./DryEye.css";
import {
  page6Header,
  FDASec,
  FDASec2,
  FDASec3,
  Just3Step,
  HowSParcs,
  page6BlueBox,
  smallFaq,
  // CommonEyes,
  CommonEye,
} from "./data6";

const DryEye = () => {
  return (
    <Fragment>
      {/* dry eye header */}
      <Container fluid className="DryEyeHeaderBg">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={6}>
                <h1 className="DryEyeHead">{page6Header.head6}</h1>
                <p className="DryEyePara">{page6Header.para6}</p>
                <ul className="dryEyeHeaderList">
                  <li className="headList1">{page6Header.List1}</li>
                  <li className="headList2">{page6Header.List2}</li>
                  <li className="headList3">{page6Header.List3}</li>
                </ul>
                <span className="mt-2 d-inline-block">
                  <p className="DryEyeHeaderSubPara">
                    {page6Header.page6BtnBefore}
                  </p>
                  <button className="DryEyeHeaderBtn">
                    {page6Header.para6Btn}
                  </button>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* dry eye header end */}

      {/* FDASection1 */}
      <Container fluid className="FdaSectionBg">
        <FDASection1 {...FDASec} />
      </Container>
      {/* FDASection1 */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <div className="FDASection1 me-auto">
                  <h2 className="FdaHead">{FDASec2.FDASec2Head}</h2>
                  <p className="FdaPara">{FDASec2.FDASec2Para}</p>
                  <ul className="FdaList">
                    <li className="FdafirstList">{FDASec2.FDASec2List1}</li>
                    <li className="FdaSecondList">{FDASec2.FDASec2List2}</li>
                    <li className="FdaThirdList">{FDASec2.FDASec2List3}</li>
                  </ul>
                </div>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDASec2.FDASec2Img} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FDASec3.FDASec3Img} alt="" />
              </Col>
              <Col lg={6}>
                <div className="FDASection1">
                  <h2 className="FdaHead py-5 mt-5">{FDASec3.FDASec3Head}</h2>
                  <p className="dryEyeParaItalic">{FDASec3.FDASec3Para}</p>
                  <div className="dryEyeStarWrapper">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <p className="FdaPara">{FDASec3.FDASec3Para2}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* how klarity */}
      <HowSection {...HowSParcs} />
      {/* how klarity end */}

      {/* just 3steps */}
      <HpJust3steps {...Just3Step} />
      {/* just 3stepsend */}

      {/* faq */}
      <FaqSmall {...smallFaq} />
      {/* faq end */}


 {/* common eye symptoms */}
 <Container fluid className="CommonEyeSympBg">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="CommonEyeSympHead">{CommonEye.CommonEyeHead}</h2>
            
            <Row className="pt-5">
              {/* 1 */}

              {CommonEye.DryEyes.map((item, index) => {
                return (
                  <Col key={index} md={6} lg={4} className="mb-4">
                    <div className={item.image}>
                      <h4 className="commonEyeBoxHead">{item.DryHead}</h4>
                      <button className="dryEyeBtn">{item.DryBtn}</button>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* common eye symptoms end */}


      {/* common eye symptoms */}
      {/* <Container fluid className="CommonEyeSympBg">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <h2 className="CommonEyeSympHead">{CommonEyes.CommonEyeHead}</h2>
            <Row>
              {CommonEyes.commonEyeArray.map((item, index) => {
                return <SimpleBox key={index} {...item} />;
              })}
            </Row>
          </Col>
        </Row>
      </Container> */}
      {/* common eye symptoms end */}
    </Fragment>
  );
};

export default DryEye;
