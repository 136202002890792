import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step13.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step13 = ({ nextStep, prevStep }) => {
  return (
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div class="TopContent">
                <h2 class="TCHeading text-center">Shipping Address</h2>
                <p class="TCDescript text-center w-50 mx-auto">
                  Your prescription will be shipped to this address if it's
                  approved.
                </p>
                {/* <!-- input box --> */}
                <input
                  class="form-control form-control-lg mt-4 searchbar- mx-auto w-75"
                  type="text"
                  placeholder="Start typing your address..."
                />
              </div>
              {/* End Body Content */}
              <FooterBtn
                className="MarginTop25"
                Next={nextStep}
                Back={prevStep}
              />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step13;
