import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step11.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step11 = ({ nextStep, prevStep, handleFormData, updateInputData, values }) => {
  return (
    //    <!-- Desktop 3 Start -->
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div class="first-text_ pt-3">
                Please review the information you've provided carefully and
                click "Confirm" to proceed.
              </div>
              {/* <!-- personal information form start --> */}
              <div class="row mt-5">
                <div class="col-md-6">
                  <div class="row p-information_">
                    <div class="col-8 align-self-center">
                      Personal Information
                    </div>
                    <div class="col-4 text-end">
                      <a href="/" type="button" class="btn first2-btn_ py-0">
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="line_"></div>
                  <div class=" mt-2">
                    <span class="p-information_">Name : </span>
                    <span class="ms-2">{values.firstName} {values.lastName}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Phone : </span>
                    <span class="ms-2">{values.phoneNumber}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Email : </span>
                    <span class="ms-2">{values.emailID}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Date of Birth : </span>
                    <span class="ms-2">{values.dobDay}/{values.dobMonth}/{values.dobYear}</span>
                  </div>
                </div>
                {/* <!-- single box form 1 start--> */}

                <div class="col-md-6">
                  <div class="row p-information_">
                    <div class="col-8 align-self-center">Medical History</div>
                    <div class="col-4 text-end">
                      <a href="/" type="button" class="btn first2-btn_ py-0">
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="line_"></div>
                  { /* <div class="p-information_ mt-2">Dry Eye Disease:</div>--> */}
                  <div class=" mt-2">
                    <span class="p-information_">Dry Eye Disease : </span>
                    <span class="ms-2">{values.everBeenDiagnosed.Dry?"Yes":"No"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Glaucoma : </span>
                    <span class="ms-2">{values.everBeenDiagnosed.glaucoma?"Yes":"No"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Cataracts : </span>
                    <span class="ms-2">{values.everBeenDiagnosed.Cataracts?"Yes":"No"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Retinal Disease : </span>
                    <span class="ms-2">{values.everBeenDiagnosed.Retinal?"Yes":"No"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Other : </span>
                    <span class="ms-2">{values.everBeenDiagnosed.conditions?"Yes":"No"}</span>
                  </div>
                </div>
                {/* <!-- single box form 1 end--> */}
                {/* <!-- single box form 2 start--> */}
                <div class="col-md-6 pt-3">
                  <div class="row p-information_">
                    <div class="col-8 align-self-center">
                      Medications Allergies
                    </div>
                    <div class="col-4 text-end">
                      <a href="/" type="button" class="btn first2-btn_ py-0">
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="line_"></div>
                  <div class=" mt-2">
                    <span class="p-information_">Medications : </span>
                    <span class="ms-2">{values.takingAnyMedications?"Not taking any medications":values.takingAnyMedicationsName}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Allergies : </span>
                    <span class="ms-2">{values.takingAnyAllergyMedications?"No Known Drug Allergies":values.takingAnyAllergyMedicationsName}</span>
                  </div>
                </div>
                {/* <!-- single box form 2 end--> */}
                {/* <!-- single box form 3 start --> */}
                <div class="col-md-6 pt-3">
                  <div class="row p-information_">
                    <div class="col-8 align-self-center">Dry Eye Scores</div>
                    <div class="col-4 text-end">
                      <a href="/" type="button" class="btn first2-btn_ py-0">
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="line_"></div>
                  <div class=" mt-2">
                    <span class="p-information_">Symptoms : </span>
                    <span class="ms-2">{values.dryEyeRange1}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Affect on daily life : </span>
                    <span class="ms-2">{values.dailyLifeEffectRange1}</span>
                  </div>
                </div>
                {/* <!-- single box form 3 end--> */}
                {/* <!-- single box form 4 start--> */}
                <div class="col-md-6 mt-3">
                  <div class="row p-information_">
                    <div class="col-8 align-self-center">Other Info</div>
                    <div class="col-4 text-end">
                      <a href="/" type="button" class="btn first2-btn_ py-0">
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="line_"></div>
                  <div class=" mt-2">
                    <span class="p-information_">Gender : </span>
                    <span class="ms-2">{values.characteristics.Select?"Female":"Male"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Smoker : </span>
                    <span class="ms-2">{values.characteristics.smoke?"Yes":"No"}</span>
                  </div>
                  <div class="pb-1">
                    <span class="p-information_">Contact Lenses : </span>
                    <span class="ms-2">{values.characteristics.contact?"Yes":"No"}</span>
                  </div>
                </div>
                {/* <!-- single box form 4 --> */}
              </div>
              {/* End Body Content */}
              <FooterBtn className="mt-3" Next={nextStep} Back={prevStep} />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step11;
