import React, { useState } from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import validator from "validator";
import "./Step1.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step1 = ({ nextStep, handleFormData, values }) => {
  const [error, setError] = useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    if (false && (validator.isEmpty(values.firstName) || validator.isEmpty(values.lastName)))
    {
      setError(true);
    }
    else
    {
      nextStep();
    }
  };
  return (
    //    <!-- Desktop 3 Start -->
    <section class="py-2">
      <div class="container-fluid ">
        <div class="row">
          <div class="col-11 max-width-q section-body_ mx-auto">
            <div class="row">
              <Headers {...headerData} />
              {/* <!-- body Content --> */}
              <div class="col-md-12 body-border_ PaddingX2rem">
                {/* <!-- topbar start --> */}
                <Progress />
                {/* <!-- body text --> */}
                <div class="fourth-text- py-3 text-center">
                  Let's get started with some information Dr.Shofner is going to
                  need for your prescription.
                </div>
                <div class="input-box-  mt-4">
                  <span class="name-">First name</span>
                  <input
                    style={{ border: error ? "1px solid tomato" : "" }}
                    type="text"
                    class="inputfield- pt-2"
                    defaultValue={values.firstName}
                    onChange={handleFormData("firstName")}
                  />
                  {error ? (
                    <label className=" ErrorMessage">
                      This is a required field
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div class="input-box- mt-3">
                  <span class="name-">Last name</span>
                  <input
                    style={{ border: error ? "1px solid tomato" : "" }}
                    type="text"
                    class="inputfield- pt-1"
                    defaultValue={values.lastName}
                    onChange={handleFormData("lastName")}
                  />
                  {error ? (
                    <label className=" ErrorMessage">
                      This is a required field
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div class="input-box- mt-3">
                  <span class="name-">Phone Number</span>
                  <input
                    style={{ border: error ? "1px solid tomato" : "" }}
                    type="text"
                    class="inputfield- pt-2"
                    defaultValue={values.phoneNumber}
                    onChange={handleFormData("phoneNumber")}
                  />
                  {error ? (
                    <label className=" ErrorMessage">
                      This is a required field
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                {/*  */}
                <FooterBtn className="MarginTop15" Next={submitFormData} />
              </div>
              {/* <!-- Footer start --> */}
              <Footers {...footerData} />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Desktop 3 End --> */}
    </section>
  );
};

export default Step1;
