import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import HeroSection from "./components/HomePage/HeroSection/HeroSection";
import Header from "./components/Header/Header";
import HowItWorks from "./components/page3/HowItWorks";
import Footer from "./components/footer/Footer";
import ImprimisRx from "./components/page4/ImprimisRx";
import Banner from "./components/page2";
import FifthPage from "./components/page5";
import DryEye from "./components/page6/DryEye";
import Page7 from "./components/page7/Page7";
import Page8 from "./components/page8/Page8";
import Page9 from "./components/page9/Page9";
import Pag10 from "./components/page10/Page10";
import Page11 from "./components/page11/Page11";

import "./App.css";
function App() {
  if(window.location.pathname === "/questions")
  {
    return (
      <>
        <Router basename="/">
          <Routes>
            <Route path={`/`} element={<HeroSection />} />
            <Route path={`/about`} element={<Banner />} />
            <Route path={`/how-it-works`} element={<HowItWorks />} />
            <Route path={`/imprimisRx`} element={<ImprimisRx />} />
            <Route path={`/5th`} element={<FifthPage />} />
            <Route path={`/dry-eye-disease`} element={<DryEye />} />
            <Route path={`/dry-eyes`} element={<Page7 />} />
            <Route path={`/8th`} element={<Page8 />} />
            <Route path={`/9th`} element={<Page9 />} />
            <Route path={`/10th`} element={<Pag10 />} />
            <Route path={`/questions`}  element={<Page11 />}/>
          </Routes>
        </Router>
      </>
    );
  }
  else
  {
    return (
      <>
        <Router basename="/">
          <Header />
          <Routes>
            <Route path={`/`} element={<HeroSection />} />
            <Route path={`/about`} element={<Banner />} />
            <Route path={`/how-it-works`} element={<HowItWorks />} />
            <Route path={`/imprimisRx`} element={<ImprimisRx />} />
            <Route path={`/5th`} element={<FifthPage />} />
            <Route path={`/dry-eye-disease`} element={<DryEye />} />
            <Route path={`/dry-eyes`} element={<Page7 />} />
            <Route path={`/8th`} element={<Page8 />} />
            <Route path={`/9th`} element={<Page9 />} />
            <Route path={`/10th`} element={<Pag10 />} />
            <Route path={`/questions`}  element={<Page11 />}/>
          </Routes>
          <Footer />
        </Router>
      </>
    );
  }
}

export default App;
