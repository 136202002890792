const page4Header = {
  page4HeaderHead: "Introducing ImprimisRx",
  page4HeaderVideo: "/movie.mp4",
};

const AboutImprimisRx = {
  AboutImprimisRxHead: "About ImprimisRx",
  AboutImprimisRxPara:
    " ImprimisRx® is pioneering a new commercial pathway in the pharmaceutical industry, using compounding pharmacies for the formulation and distribution of high quality, proprietary, compounded formulations. We are patient focused and we do this by partnering with healthcare providers to ensure high quality medications at affordable prices for your patients.",
};

const page4Quality = {
  page4QualityHead: " Providing High-Quality Formulations",
  page4QualityPara:
    "ImprimisRx® pharmacies are committed to delivering high-quality formulations from our patient specific 503A pharmacy and our FDA-Registered Outsourcing Facility.",
  page4QualityImg: "https://uat.visionology.com/images/Pharmacy/high-quality-formulations.png",
};

const page4Solving = {
  page4SolvingHead: "Solving Unmet Needs Through Innovation",
  page4SolvingPara:
    " The healthcare industry is experiencing price increases and shortages for imperative treatments that are in demand. We strive to solve these challenges through innovative compounded alternatives, and those inaccessible due to drug shortages.",
  page4SolvingPara2:
    "Compounded Choice™ provides the opportunity to obtain high quality medications for your patients that are in high demand, but may have been previously unavailable.",
  page4SolvingImg: "https://uat.visionology.com/images/Pharmacy/solving-unmet-needs.jpg",
};

const MakingMedications = {
  MakingMedicationsHead: "Making Medications Accessible and Affordable",
  MakingMedicationsPara:
    "We work with physicians and patients to provide access to alternative compounded medicines at affordable prices.",
  MakingMedicationsImg: "https://uat.visionology.com/images/Pharmacy/making-medications-affordable.jpg",
};

const Page4FDAApproved = {
  FDAApprovedImg: "https://uat.visionology.com/images/Pharmacy/image-header-2.png",
  FDAApprovedHead: " About Visionology",
  FDAApprovedPara:
    "Visionology offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain,Visionology can offer medications up to 80% off of what you arecurrently paying.",
  FDAApprovedBtn: "Learn More",
};

const CalltoAction = {
  CalltoActionImg: "https://uat.visionology.com/images/Pharmacy/ophthalmologists-sign-up.jpg",
  CalltoActionHead: "Call to Action for Doctors",
  CalltoActionPara:
    " Visionlogy offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain, Visionology can offer medications up to 80% off of what you are currently paying.",
  CalltoActionBtn: "Learn More",
};

const Page4FdBoxesData = {
  FdBoxe1Head: "Dry Eye Disease",
  FdBoxe1btn1: "Start My Online Visit",
  FdBoxe1btn2: "Learn More",
  FdBoxe2Head: "SPARCs Diagnostic",
  FdBoxe2btn1: "Start My Online Visit",
  FdBoxe2btn2: "Learn More",
};

const page4Blog = {
  blogHead: "Featured Articles",
  BlogItem: [
    {
      BlogImg: "https://uat.visionology.com/images/Blog/allergies-and-dry-eye-disease.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/covid-dry-eyes.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/dry-eye-disease-guide.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
  ],
};

export {
  page4Header,
  AboutImprimisRx,
  page4Quality,
  page4Solving,
  MakingMedications,
  Page4FDAApproved,
  CalltoAction,
  Page4FdBoxesData,
  page4Blog,
};
