import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step12.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step12 = ({ nextStep, prevStep }) => {
  return (
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div class="TopContent">
                <h2 class="TCHeading">Your prescription is almost ready.</h2>
                <p class="TCDescript">
                  Your dry eye assessment and medical history will be sent to
                  Dr. Shofner to review and approve.
                  <br />
                  <br />
                  <span class="pe-md-5 d-inline-block">
                    Click "Next" to add your payment details and activate the
                    prescription after Dr. Shofner's approval.
                  </span>
                </p>
                <div class="VisinologyBox mt-4">
                  <ul class="VisinologyBoxUl">
                    <li class="TopHead">
                      <span>Visionology monthly membership</span>
                      <span>$69</span>
                    </li>
                    <li class="BoxTxt">
                      <span>Klarity-C Eye Drops (30-day supply)</span>
                      <span>$69</span>
                    </li>
                    <li class="BoxTxt">
                      <span>Shipping</span>
                      <span class="SpanFree">Free</span>
                    </li>
                    <li class="BoxTxt">
                      <span>Doctor Visit (Telemedicine)</span>
                      <span class="SpanFree">Free</span>
                    </li>
                  </ul>
                </div>
                <div class="CancelList">
                  <ul class="CancelListUl">
                    <li>
                      <span class="CorrectSvg">
                        <svg
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Cancel Anytime
                    </li>
                    <li>
                      <span class="CorrectSvg">
                        <svg
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z"
                            fill="white"
                          />
                        </svg>{" "}
                      </span>
                      100% Money-Back Guarantee
                    </li>
                    <li>
                      <span class="CorrectSvg">
                        <svg
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z"
                            fill="white"
                          />
                        </svg>{" "}
                      </span>
                      Automated Billing So You Never Miss a Refill
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Body Content */}
              <FooterBtn
                className="MarginTop8"
                Next={nextStep}
                Back={prevStep}
              />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step12;
