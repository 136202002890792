import React, { Fragment } from "react";
import "./SimpleBox.css";
import { Col } from "react-bootstrap";

const SimpleBox = (props) => {
  const { CommonEyeSubHead, CommonEyeBtn } = props;
  return (
    <Fragment>
      <Col md={4} className="mb-4">
        <div className="innerWrapperr border">
          <h1 className="BannerSub_heading">{CommonEyeSubHead}</h1>
          <div className="d-flex align-items-center">
            <button className="innerWrapperrBtn">{CommonEyeBtn}</button>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default SimpleBox;
