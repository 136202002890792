import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FDAApproved from "../page2/FDAApproved/index";
import FdBoxes from "../page2/FdBoxes/FdBoxes";
import Blog from "../HomePage/blog/Blog";
import "./ImprimisRx.css";
import {
  page4Header,
  AboutImprimisRx,
  page4Quality,
  page4Solving,
  MakingMedications,
  Page4FDAApproved,
  CalltoAction,
  Page4FdBoxesData,
  page4Blog,
} from "./data4";

const ImprimisRx = () => {
  return (
    <Fragment>
      {/* header */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <h1 className="ImprimisRxHead">{page4Header.page4HeaderHead}</h1>
            <div className="videoWrapper">
              <div className="videoOverLay">
                <i class="fa fa-play-circle-o" aria-hidden="true"></i>
              </div>
              <video className="videoBox" controls>
                <source src={page4Header.page4HeaderVideo} type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Container>
      {/* header end */}

      {/* about */}
      <Container fluid className="ImprimisRxAboutBg">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <h2 className="ImprimisRxAboutHead">
              {AboutImprimisRx.AboutImprimisRxHead}
            </h2>
            <p className="ImprimisRxAboutPara">
              {AboutImprimisRx.AboutImprimisRxPara}
            </p>
          </Col>
        </Row>
      </Container>
      {/* aboutend */}

      {/* providing Quality */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-md-5">
            <Row>
              <Col md={7} lg={6}>
                <h2 className="providingQualityHead HeadingMaxW">
                  {page4Quality.page4QualityHead}
                </h2>
                <p className="providingQualityPara w-75">
                  {page4Quality.page4QualityPara}
                </p>
              </Col>
              <Col md={5} lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={page4Quality.page4QualityImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* providing Quality end */}

      {/* providing Quality */}
      <Container fluid>
        <Row className="pt-md-5 mb-5">
          <Col xs={11} className="max-width mx-auto py-md-5">
            <Row>
              <Col md={5} lg={6} className="text-center order-md-0 order-1">
                <img
                  className="img-fluid"
                  src={page4Solving.page4SolvingImg}
                  alt=""
                />
              </Col>
              <Col md={7} lg={6} className="order-md-1 order-0">
                <h2 className="providingQualityHead ms-auto">
                  {page4Solving.page4SolvingHead}
                </h2>
                <p className="providingQualityPara w-75">
                  {page4Solving.page4SolvingPara}
                </p>
                <p className="providingQualityPara w-75">
                  {page4Solving.page4SolvingPara2}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* providing Quality end */}

      {/* providing Quality */}
      <Container fluid>
        <Row className="pt-md-5 pb-5">
          <Col xs={11} className="max-width mx-auto pt-md-5 pb-5">
            <Row>
              <Col md={7} lg={6}>
                <h2 className="providingQualityHead ">
                  {MakingMedications.MakingMedicationsHead}
                </h2>
                <p className="providingQualityPara w-75">
                  {MakingMedications.MakingMedicationsPara}
                </p>
              </Col>
              <Col md={5} lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={MakingMedications.MakingMedicationsImg}
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* providing Quality end */}

      {/* about visionolgy */}
      <Container fluid className="AboutVisionologyBg px-0">
        <FDAApproved {...Page4FDAApproved} />
      </Container>
      {/* about visionolgyend */}

      {/* call to action */}
      <Container fluid className="calltoactionBg pb-5">
        <Row className="pb-5">
          <Col xs={11} className="max-width mx-auto calltoActionTop">
            <Row>
              <Col md={7} lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={CalltoAction.CalltoActionImg}
                  alt=""
                />
              </Col>
              <Col md={5} lg={6}>
                <h2 className="callToActionHead">
                  {CalltoAction.CalltoActionHead}
                </h2>
                <p className="callToActionPara">
                  {CalltoAction.CalltoActionPara}
                </p>
                <button className="callToActionBtn">
                  {CalltoAction.CalltoActionBtn}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* call to actionend */}

      {/* FdBoxes */}
      <Container fluid className="FdBoxes">
        <FdBoxes {...Page4FdBoxesData} />
      </Container>
      {/* FdBoxes end */}

      {/* blog */}
      <Blog {...page4Blog} />
      {/* blog end */}
    </Fragment>
  );
};

export default ImprimisRx;
