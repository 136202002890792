import React, {useState, useEffect} from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import MM from "../../../Asset/MM.svg";
import "./Step10.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
import $ from "jquery";
const Step10 = ({ nextStep, prevStep, handleFormData, updateInputData, values }) => {
  
  const [Month, setMonth] = React.useState(values.dobMonth);
  const [Day, setDay] = React.useState(values.dobDay);
  const [Year, setYear] = React.useState(values.dobYear);

  const MMDropdownHandler = () => {
    $("#dropdown2").addClass("d-none");
    $("#dropdown3").addClass("d-none");
    $("#dropdown1").toggleClass("d-none");
  };
  const DDDropdownHandler = () => {
    $("#dropdown1").addClass("d-none");
    $("#dropdown3").addClass("d-none");
    $("#dropdown2").toggleClass("d-none");
  };
  const YYDropdownHandler = () => {
    $("#dropdown1").addClass("d-none");
    $("#dropdown2").addClass("d-none");
    $("#dropdown3").toggleClass("d-none");
  };
  const DropValueHandler = (value) => {
    if (value.month) {
      $("#dropdown1").toggleClass("d-none");
      setMonth(value.month);
    }
    if (value.day) {
      $("#dropdown2").toggleClass("d-none");
      setDay(value.day);
    }
    if (value.year) {
      $("#dropdown3").toggleClass("d-none");
      setYear(value.year);
    }
  };
  useEffect(() => {
    updateInputData("dobDay",Day);
    updateInputData("dobMonth",Month);
    updateInputData("dobYear",Year);
  });
  return (
    //    <!-- Desktop 3 Start -->
    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-11 max-width-q section-body_ mx-auto">
          <div class="row">
            <Headers {...headerData} />
            {/* <!-- body Content --> */}
            <div class="col-md-12 body-border_ PaddingX2rem">
              {/* <!-- topbar start --> */}
              <Progress />
              {/* <!-- body text --> */}
              <div class="first-text_ pt-4">
                We also need your date of birth <br />
                for our patient records.
              </div>
              <ul className="dropiconWrapper mt-5 ">
                <li className="dropicon">
                  <span onClick={MMDropdownHandler} className="IconTxt">
                    {Month}
                  </span>
                  <span className="IconName" onClick={MMDropdownHandler}>
                    <img src={MM} alt="" className="IconNameImg" />
                  </span>
                  <ul id="dropdown1" className="dropdown d-none">
                    <li onClick={DropValueHandler.bind(this, { month: "01" })}>
                      Jan
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "02" })}>
                      Feb
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "03" })}>
                      Mar
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "04" })}>
                      Apr
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "05" })}>
                      May
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "06" })}>
                      Jun
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "07" })}>
                      July
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "08" })}>
                      Aug
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "09" })}>
                      Sep
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "10" })}>
                      Oct
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "11" })}>
                      Nov
                    </li>
                    <li onClick={DropValueHandler.bind(this, { month: "12" })}>
                      Dec
                    </li>
                  </ul>
                </li>
                <li className="dropicon">
                  <span onClick={DDDropdownHandler} className="IconTxt">
                    {Day}
                  </span>
                  <span className="IconName" onClick={DDDropdownHandler}>
                    <img src={MM} alt="" className="IconNameImg" />
                  </span>
                  <ul id="dropdown2" className="dropdown d-none">
                    <li onClick={DropValueHandler.bind(this, { day: "1" })}>
                      1
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "2" })}>
                      2
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "3" })}>
                      3
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "4" })}>
                      4
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "5" })}>
                      5
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "6" })}>
                      6
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "7" })}>
                      7
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "8" })}>
                      8
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "9" })}>
                      9
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "10" })}>
                      10
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "11" })}>
                      11
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "12" })}>
                      12
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "13" })}>
                      13
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "14" })}>
                      14
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "15" })}>
                      15
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "16" })}>
                      16
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "17" })}>
                      17
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "18" })}>
                      18
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "19" })}>
                      19
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "20" })}>
                      20
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "21" })}>
                      21
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "22" })}>
                      22
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "23" })}>
                      23
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "24" })}>
                      24
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "25" })}>
                      25
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "26" })}>
                      26
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "27" })}>
                      27
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "28" })}>
                      28
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "29" })}>
                      29
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "30" })}>
                      30
                    </li>
                    <li onClick={DropValueHandler.bind(this, { day: "31" })}>
                      31
                    </li>
                  </ul>
                </li>
                <li className="dropicon">
                  <span onClick={YYDropdownHandler} className="IconTxt">
                    {Year}
                  </span>
                  <span className="IconName" onClick={YYDropdownHandler}>
                    <img src={MM} alt="" className="IconNameImg" />
                  </span>
                  <ul id="dropdown3" className="dropdown d-none">
                    <li onClick={DropValueHandler.bind(this, { year: "1980" })}>
                      1980
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1981" })}>
                      1981
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1982" })}>
                      1982
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1983" })}>
                      1983
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1984" })}>
                      1984
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1985" })}>
                      1985
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1986" })}>
                      1986
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1987" })}>
                      1987
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1988" })}>
                      1988
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1989" })}>
                      1989
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1990" })}>
                      1990
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1991" })}>
                      1991
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1992" })}>
                      1992
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1993" })}>
                      1993
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1994" })}>
                      1994
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1995" })}>
                      1995
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1996" })}>
                      1996
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1997" })}>
                      1997
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1998" })}>
                      1998
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "1999" })}>
                      1999
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2000" })}>
                      2000
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2001" })}>
                      2001
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2002" })}>
                      2002
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2003" })}>
                      2003
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2004" })}>
                      2004
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2005" })}>
                      2005
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2006" })}>
                      2006
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2007" })}>
                      2007
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2008" })}>
                      2008
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2009" })}>
                      2009
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2010" })}>
                      2010
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2011" })}>
                      2011
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2012" })}>
                      2012
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2013" })}>
                      2013
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2014" })}>
                      2014
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2015" })}>
                      2015
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2016" })}>
                      2016
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2017" })}>
                      2017
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2018" })}>
                      2018
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2019" })}>
                      2019
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2020" })}>
                      2020
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2021" })}>
                      2021
                    </li>
                    <li onClick={DropValueHandler.bind(this, { year: "2022" })}>
                      2022
                    </li>
                    
                  </ul>
                </li>
              </ul>
              {/* End Body Content */}
              <FooterBtn
                className="MarginTop25"
                Next={nextStep}
                Back={prevStep}
              />
            </div>
            {/* <!-- Footer start --> */}
            <Footers {...footerData} />
            {/* <!-- Footer End --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step10;
