import React, { Fragment } from "react";
import { Col, Container, Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./HomepageAboutUs.css";

const HomepageAboutUs = (props) => {
  const { Caring, frequent, About, frequentImg } = props;
  return (
    <Fragment>
      {" "}
      {/* aboutUs */}
      <Container fluid className="AboutUsBG ">
        <Row>
          <Col xs={11} className="mx-auto max-width pb-5">
            <Row>
              <Col md={6} className=" py-4">
                <h2 className="AboutUsHead">{Caring}</h2>
                <p className="AboutUsPara">{frequent}</p>
                <NavLink to={'/about'} className="AboutBtn">{About}</NavLink>
              </Col>
              <Col md={6} className=" text-center">
                <img className="img-fluid" src={frequentImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* aboutUs end */}
    </Fragment>
  );
};

export default HomepageAboutUs;
