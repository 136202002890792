import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./MedicationBtn.css";

const MedicationBtn = (props) => {
  const { StartYourBtn, StartYourBtn2Before, StartYourBtn2 } = props;
  return (
    <Fragment>
      <Container fluid className="medicationBtnBg">
        <Row className="pb-5">
          <Col md={6} className="text-md-end text-center">
            <a href="https://uatquiz.visionology.com" className="HeroBtn text-decoration-none" target={'_blank'} rel="noreferrer">{StartYourBtn}</a>
          </Col>
          <Col md={6} className="text-md-start text-center ">
            <div className="M_BtnBeforeWrapper">
              <p className="medicationBtnBefore">{StartYourBtn2Before}</p>
              <a href={`tel:+1(844) 637 2020`} className="medicationPhoneBTN">
                <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                {StartYourBtn2}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MedicationBtn;
