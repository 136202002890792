const page3Header = {
  headerHead: "How It Works",
  headerPara:
    " Emotional copy that speaks to why eye care need to be treated with care. Visionology knows what it takes and has created products that does just that.",
};

const Page3HeroSectionbreak = [
  {
    Formulated: "Doctor Prescribed Medication",
    FormulatedImg: "https://uat.visionology.com/images/HowItWorks/group-52.png",
  },
  {
    Formulated: "Delivered to Your Door",
    FormulatedImg: "https://uat.visionology.com/images/HowItWorks/group-53.png",
  },
  {
    Formulated: "Fraction of Other Medications",
    FormulatedImg: "https://uat.visionology.com/images/HowItWorks/group-41.png",
  },
];

const QuestionSec = {
  QuestionSecHead: " 1. Answer a Few Questions About Your Health",
  QuestionSecPara:
    "About how the Online Visit works. Fill out a quick quiz that includes inputting symptoms, personal information, etc. that allows the doctor to quickly follow up with you and provide a prescription.",
  QuestionSecList1: "Free Online Visit",
  QuestionSecList2: "Secure and HPPA compliant online portal",
  QuestionSecImg: "https://uat.visionology.com/images/HowItWorks/step1.jpg",
};

const QuestionSec2 = {
  QuestionSec2Head: "2. Connect with a Licensed Eye Doctor",
  QuestionSec2Para:
    "All details from the online visit is given to a licensed eye doctor. Often times, the online questions provide doctors more information than would be collected in person.",
  QuestionSec2Para2:
    "A licensed physician will contact you directly via email to discuss the results of your online result and prescribe a prescription if needed.",
  QuestionSec2List1: "No prior authorization required",
  QuestionSec2List2: "80% Off Prescribed Medication",
  QuestionSec2Img: "https://uat.visionology.com/images/HowItWorks/step2.jpg",
};

const QuestionSec3 = {
  QuestionSec3Head: "3. Prescribed Medication Delivered",
  QuestionSec3Para:
    " If the treatment is prescribed, you’ll receive medication directly to your home a few days later. .",
  QuestionSec3Para2:
    "Expect a delivery of your eye medication every month with enough quantity to last you til your next delivery",
  QuestionSec3List1: "No commitment / Cancel Anytime",
  QuestionSec3List2: "Preservative Free Medication",
  QuestionSec3Img: "https://uat.visionology.com/images/HowItWorks/step3.png",
  QuestionSec3SubPara:
    "*If prescribed, medication is just $1 + Shipping the first month and $59 / month afterwards. ",
  QuestionSec3Btn: "Start Free Online Visit",
  QuestionSec3Btn2Before: "Speak to a Representative:",
  QuestionSec3Btn2: "(844) 637 2020",
};

const Page3FDAApproved = {
  FDAApprovedImg: "https://uat.visionology.com/images/HowItWorks/background-fda-section.png",
  FDAApprovedHead: " FDA Approved Ingredients and FDA Regulated Pharmacy.",
  FDAApprovedPara:
    "Visionology offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain,Visionology can offer medications up to 80% off of what you arecurrently paying.",
  FDAApprovedBtn: "Learn More",
};


const Just3Step = {
  justThreeHead: "",
  justThreeHeadArray: [
    {
      stepCss: "just3BoxImg1",
      justImgText: "1",
      just3Text: "On-Going Care",
      just3Para:
        "Signing up for visionology service means continued and on-going access to our suite of healthcare professionals.",
    },
    {
      stepCss: "just3BoxImg2",
      justImgText: "2",
      just3Text: "Affordable",
      just3Para:
        "By removing the middlemen, we’re able to cut down cost of medication to a fraction of what other eye medication would cost you. Eye care is still affordable even if you don’t have medication. ",
    },
    {
      stepCss: "just3BoxImg3",
      justImgText3: "3",
      just3Text: "Real Medication",
      just3Para:
        " All our medication is made by our sister Pharmacy, ImprimusRX, an FDA regulated pharmacy. ",
    },
  ],
};

const FdaBoxes = {
  page3BlueBox: [
    {
      justImgText: "",
      just3Text: "On-Going Care",
      just3Para:
        " Signing up for visionology service means continued and on-going access to our suite of healthcare professionals.",
    },
    {
      justImgText: "",
      just3Text: "Affordable ",
      just3Para:
        " By removing the middlemen, we’re able to cut down cost of medication to a fraction of what other eye medication would cost you. Eye care is still affordable even if you don’t have medication. ",
    },
    {
      justImgText: "",
      just3Text: "Real Medication",
      just3Para:
        " All our medication is made by our sister Pharmacy, ImprimusRX, an FDA regulated pharmacy. ",
    },
  ],
};

const page3Quality = {
  page3QualityHead: "High quality medications at a fraction of the cost",
  page3QualityPara:
    " Philosophically we believe in providing high quality medication to patients at an affordable cost.",
  page3QualityImg: "https://place-hold.it/600x402",
};

const Page3FAQ = {
  FAQHead: "FAQs",
  FAQArray: [
    {
      AccordionBtn: " How do I find the right information faster?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "How do I start the online intake form?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "What are estimated shipping times?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "Do I need insurance?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
  ],
};

const StartYour = {
  medicationsHead: "Start Your Online Visit",
  MedicationArray: [
    {
      Noprior: "No prior authorizations are required",
      NopriorImg: "https://uat.visionology.com/images/HowItWorks/group-48.png",
    },
    {
      Noprior: "No preservatives in our ingredients",
      NopriorImg: "https://uat.visionology.com/images/HowItWorks/group-50.png",
    },
    {
      Noprior: "Prescriptions reviewed by licensed physicians",
      NopriorImg: "https://uat.visionology.com/images/HowItWorks/group-52.png",
    },
  ],
};

const StartYourBtn = {
  StartYourBtn: "Start free Online Visit",
  StartYourBtn2Before: "Speak to a Representative:",
  StartYourBtn2: "(844) 637 2020",
};
export {
  page3Header,
  Page3HeroSectionbreak,
  QuestionSec,
  QuestionSec2,
  QuestionSec3,
  Page3FDAApproved,
  FdaBoxes,
  page3Quality,
  Page3FAQ,
  StartYour,
  StartYourBtn,
  Just3Step,
};
