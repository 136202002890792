import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FDAApprvoed from "./FDAApproved/index";
import Blog from "../HomePage/blog/Blog";
import BlueBox from "../HomePage/blueBox/BlueBox";
import FdBoxes from "./FdBoxes/FdBoxes";
import "./Banner.css";
import {
  page2Hero,
  WeBelieve,
  AboutVisinology,
  VisinologySkip,
  PatientFirst,
  FDAApproved,
  FdBoxesData,
  Page2Blog,
} from "./data2";

const Index = () => {
  return (
    <Fragment>
      {/* HeroSection */}
      <Container fluid className="BannerW3s">
        <Row className="py-5 w-100">
          <Col xs={11} className="mx-auto max-width ">
            <Row>
              <Col lg={10} className="">
                <div className="bannerContent mt-5">
                  <p className="BannerSub_Description">
                    {page2Hero.Innovating}
                  </p>
                  <h1 className="BannerW3s_Heading BHeadings">
                    {page2Hero.OurMission}
                  </h1>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* HeroSection end */}

      {/* BelieveEye */}
      <Container fluid className="BelieveEye py-5">
        <Row className="py-5">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col md={12} className="mb-5">
                <h1 className="BannerW3s_Heading BEyeHeading text-center">
                  {WeBelieve.WeBbelieveEye}
                </h1>
              </Col>
              <BlueBox Bluebox={WeBelieve.page2BlueBox[0]} />
              <BlueBox Bluebox={WeBelieve.page2BlueBox[1]} />
              <BlueBox Bluebox={WeBelieve.page2BlueBox[2]} />
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End BelieveEye */}

      {/* About Visinology */}
      {/* <Container fluid className="AboutVisinology">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row>
              <Col lg={10} className="">
                <div className="AboutVisinologyImg "></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> */}

      {/* About Visinology Content */}
      <Container fluid className="AboutVisiContent">
        <Row className="">
          <img
            className="img-fluid"
            src={AboutVisinology.VisionologyImg}
            alt=""
          />
          <Col xs={11} className="mx-auto max-width ">
            <Row className="marginN10">
              <Col lg={10} className="mx-auto mb-5">
                <div className="AVContentrapper">
                  <h1 className="BannerW3s_Heading text-center">
                    {AboutVisinology.Visionology}
                  </h1>
                  <p className="BannerSub_Description text-center">
                    {AboutVisinology.VisionologyDescription}
                  </p>
                </div>
              </Col>
              <Col md={6} className="mt-5">
                <div className="HarrowImg">
                  <img src={AboutVisinology.HarrowImg} alt="" />
                </div>
                <div className="HarrowContent">
                  <h1 className="BannerW3s_Heading my-5">
                    {AboutVisinology.HarrowHeading}
                  </h1>
                  <p className="BannerSub_Description HarrowDescrpt">
                    {AboutVisinology.HarrowDesc}
                  </p>
                  <button className="HeroBtn HarrowBtn">
                    {AboutVisinology.HarrowBtn}
                  </button>
                </div>
              </Col>
              <Col md={6} className="mt-5">
                <div className="HarrowImg">
                  <img src={AboutVisinology.ImprimisRxImg} alt="" />
                </div>
                <div className="HarrowContent">
                  <h1 className="BannerW3s_Heading my-5">
                    {AboutVisinology.ImprimisRxHead}
                  </h1>
                  <p className="BannerSub_Description HarrowDescrpt">
                    {AboutVisinology.ImprimisRxDesc}
                  </p>
                  <button className="HeroBtn HarrowBtn">
                    {AboutVisinology.ImprimisRxBtn}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End About Visinology */}

      {/* VisinologySkip */}
      <Container fluid className="VisinologySkip my-5 py-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={10} className="mx-auto mb-5">
                <div className="">
                  <h1 className="BannerW3s_Heading text-center">
                    {VisinologySkip.VisinologySkipHead}
                  </h1>
                  <p className="BannerSub_Description VisinologySkipDecrpt mt-5">
                    {VisinologySkip.VisinologySkipPara}
                  </p>
                </div>
              </Col>
              <Col lg={3} md={6} className="VBollWrapper">
                <div className="VisinologyBoll ">
                  <div className="Boll"></div>
                  <div className="BollContent">
                    {VisinologySkip.VisinologySkipB1}
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} className="VBollWrapper">
                <div className="VisinologyBoll ">
                  <div className="Boll"></div>
                  <div className="BollContent">
                    {VisinologySkip.VisinologySkipB2}
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} className="VBollWrapper">
                <div className="VisinologyBoll ">
                  <div className="Boll"></div>
                  <div className="BollContent">
                    {VisinologySkip.VisinologySkipB3}
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} className=" ">
                <div className="VisinologyBoll ">
                  <div className="Boll"></div>
                  <div className="BollContent">
                    {VisinologySkip.VisinologySkipB4}
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="text-center mt-5">
                  <button className="HeroBtn">
                    {VisinologySkip.VisinologySkipBtn}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End VisinologySkip */}

      {/* Patient First */}
      <Container fluid className="PatientFirst mt-5 py-5">
        <Row className="py-5">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col md={6} className=" d-flex align-items-center">
                <div className="PatientContent ">
                  <h1 className="BannerW3s_Heading mb-5">
                    {PatientFirst.PatientFirstHead}
                  </h1>
                  <p className="BannerSub_Description">
                    {PatientFirst.PatientFirstPara}
                  </p>
                </div>
              </Col>
              <Col md={6} className="">
                <div className="PatientImg">
                  <img
                    src={PatientFirst.PatientFirstImg}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Col>

              <Col md={6} className="mt-5 pt-md-5 order-md-0 order-1">
                <div className="PatientImg">
                  <img
                    src={PatientFirst.EmpoweringImg}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Col>
              <Col
                md={6}
                className=" d-flex align-items-center order-md-1 order-0 mt-md-0 mt-5"
              >
                <div className="PatientContent ms-auto">
                  <h1 className="BannerW3s_Heading mb-5">
                    {PatientFirst.EmpoweringHead}
                  </h1>
                  <p className="BannerSub_Description">
                    {PatientFirst.EmpoweringPara}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End Patient First */}

      {/* FDA */}
      <Container fluid className="FDAApprovedContent px-0">
        <FDAApprvoed {...FDAApproved} />
      </Container>
      <Container fluid className="FDAApprovedContent pb-5">
        <FdBoxes {...FdBoxesData} />
      </Container>
      {/* End FDAApproved */}

      {/* Blog */}
      <Blog {...Page2Blog} />
      {/* End Blog */}
    </Fragment>
  );
};

export default Index;
