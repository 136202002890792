import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./HowSection.css";

const HowSection = (props) => {
  const {
    HowSParcsHead,
    HowSParcsSubHead,
    HowSParcsPara,
    HowSParcsSubHead2,
    HowSParcsPara2,
    HowSParcsSubHead3,
    HowSParcsPara3,
    HowSParcsBtn,
    HowSParcsbtn2,
  } = props;
  return (
    <Fragment>
      {" "}
      {/* VisinologySkip */}
      <Container fluid className="SparcsWork py-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={12} className="mx-auto">
                <h1 className="BannerW3s_Heading text-center mb-5">
                  {HowSParcsHead}
                </h1>
              </Col>
              <Col md={6} className=" ">
                <div className="SparcsWorkContent">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcsSubHead}
                  </h1>
                  <p className="me-md-4 mt-4 HowSParcsPara">{HowSParcsPara}</p>
                </div>
                <div className="SparcsWorkContent mt-5">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcsSubHead2}
                  </h1>
                  <p className="me-md-4 mt-4 HowSParcsPara">{HowSParcsPara2}</p>
                </div>
                <div className="SparcsWorkContent mt-5">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    {HowSParcsSubHead3}
                  </h1>
                  <p className="me-md-4 mt-4 HowSParcsPara">{HowSParcsPara3}</p>
                </div>
              </Col>
              <Col md={6} className="">
                <div className="SparcsWorkCImg"></div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-center my-5 pt-5">
                  <button className="HeroBtn mt-0 me-4">{HowSParcsBtn}</button>
                  <button className="medicationPhoneBTN">
                    {HowSParcsbtn2}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* End VisinologySkip */}
    </Fragment>
  );
};

export default HowSection;
