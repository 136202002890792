const welcomeback = {
  Welcome: "Welcome Back!",
  Log: "Log in with your phone number to continue.",
  Continue: "Continue",
};
const Having = {
  BoxHead: "Having trouble logging in?",
  Speak: "Speak to Customer Support",
};
const Having2 = {
  BoxHead: "Not a patient?",
  Speak: "Enter the Doctor Portal",
};
export { welcomeback, Having, Having2 };
