import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HeroSectionBreak from "../HeroSectionBreak/HeroSectionBreak";
import Faqs from "../Faqs/Faqs";
import Medications from "../medications/Medications";
import Blog from "../blog/Blog";
import HomepageHero from "../HomepageHero/HomepageHero";
import HomepageAboutUs from "../homepageAboutUs/HomepageAboutUs";
import HpJust3steps from "../HpJust3Steps/HpJust3Steps";
import MedicationBtn from "../medicationBtn/MedicationBtn";
import {
  Homepagehero,
  HeroSectionbreak,
  HomepageAboutus,
  Medication,
  Just3Step,
  DryEye,
  RedDryEye,
  SparcDiagnostic,
  CommonEye,
  Customers,
  FAQ,
  Over,
  Blogs,
} from "./data";
import "./HeroSection.css";

const HeroSection = () => {
  const OverSlider = {
    loop: true,
    margin: 18,
    items: 2,
    dots: true,
    nav: true,
    navSpeed: 1000,
    navText: [
      `<i class="fa fa-angle-left" aria-hidden="true"></i>`,
      `<i class="fa fa-angle-right" aria-hidden="true"></i>`,
    ],
    responsiveClass: true,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        margin: 10,
        nav: false,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  const CustomerTrustSlider = {
    loop: true,
    margin: 10,
    items: 2,
    dots: true,
    nav: true,
    stagePadding: 90,
    navSpeed: 1000,
    navText: [
      `<i class="fa fa-angle-left" aria-hidden="true"></i>`,
      `<i class="fa fa-angle-right" aria-hidden="true"></i>`,
    ],
    responsiveClass: true,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        margin: 30,
      },
      576: {
        items: 1,
      },
      1200: {
        items: 2,
      },
    },
  };
  return (
    <Fragment>
      {/* home pade hero */}
      <HomepageHero {...Homepagehero} />
      {/* home pade heroend */}

      {/* herosection break */}
      <HeroSectionBreak HeroSectionbreak={HeroSectionbreak} />
      {/* herosection break */}

      {/* about us */}
      <HomepageAboutUs {...HomepageAboutus} />
      {/* about usend */}

      {/* medications */}
      <Medications {...Medication} />
      <MedicationBtn />
      {/* medications end */}

      {/* just 3steps */}
      <HpJust3steps {...Just3Step} />
      {/* just 3stepsend */}

      {/* dry eye disease */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <Row>
              <Col lg={6}>
                <h2 className="dryEyeDHead mt-4">{DryEye.DryEyeHeading}</h2>
                <p className="dryEyeIPara">{DryEye.Treatment}</p>
                <p className="dryEyeDPara">{DryEye.millions}</p>
                <p className="dryEyeDPara">{DryEye.Visionology}</p>
                <a href="https://uat.visionology.com/imprimisRx" target={'_blank'} rel="noreferrer" className="text-decoration-none HeroBtn mb-4">{DryEye.Learn}</a>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={DryEye.DryeyeImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* dry eye disease end */}

      {/* red eye disease */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <Row>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={RedDryEye.DryeyeImg} alt="" />
              </Col>
              <Col lg={6}>
                <h2 className="dryEyeDHead mt-4">{RedDryEye.DryEyeHeading}</h2>
                <p className="dryEyeIPara">{RedDryEye.Treatment}</p>
                <p className="dryEyeDPara">{RedDryEye.millions}</p>
                <p className="dryEyeDPara">{RedDryEye.Visionology}</p>
                <a href="https://uat.visionology.com/imprimisRx" target={'_blank'} rel="noreferrer" className="text-decoration-none HeroBtn mb-4">{RedDryEye.Learn}</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* red eye disease end */}

      {/* sparc diagnostic eye disease */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <Row>
              <Col lg={6}>
                <h2 className="dryEyeDHead mt-4">{SparcDiagnostic.SPARC}</h2>
                <p className="dryEyeDPara">{SparcDiagnostic.SPARCScontrast}</p>
                <p className="dryEyeDPara">
                  {SparcDiagnostic.dryEyeDPara.map((item, index) => {
                    return (
                      <span key={index} className="diagnosticSteps">
                        {item}
                      </span>
                    );
                  })}
                </p>
                <button className="diagnosticStartBtn d-block d-lg-inline-block">
                  {SparcDiagnostic.HeroBtn}
                </button>
                <a href="https://uat.visionology.com/imprimisRx" target={'_blank'} rel="noreferrer" className="text-decoration-none HeroBtn mt-2 mb-4 d-block d-lg-inline-block">{SparcDiagnostic.Learn}</a>
              </Col>
              <Col lg={6} className="text-center">
                <img
                  className="img-fluid"
                  src={SparcDiagnostic.SPARCImg}
                  alt=""
                />
              </Col>
            </Row>
            <div className="text-center mt-4">
            <a href="https://uatquiz.visionology.com" className="HeroBtn text-decoration-none" target={'_blank'} rel="noreferrer">Start Free Online Visit</a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* sparcs diagnostic eye disease end */}

      {/* common eye symptoms */}
      <Container fluid className="commonEyeBg">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="commonEyeHead">{CommonEye.CommonEyeHead}</h2>
            <p className="commonEyePara">{CommonEye.SPARCScontrast}</p>
            <Row className="pt-5">
              {/* 1 */}

              {CommonEye.DryEyes.map((item, index) => {
                return (
                  <Col key={index} md={6} lg={4} className="mb-4">
                    <div className={item.image}>
                      <h4 className="commonEyeBoxHead">{item.DryHead}</h4>
                      <button className="dryEyeBtn">{item.DryBtn}</button>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* common eye symptoms end */}

      {/* CustomerTrustSlider */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="mx-auto max-width CustomerTrust">
            <Row>
              <Col lg={12} className="mb-5 mb-lg-0">
                <h1 className="HeroHead text-center mb-5 pb-4">
                  {Customers.CustomersHeading}
                </h1>
              </Col>
              {/* Carousel */}
              <OwlCarousel
                className="owl-theme CustomerTrustSlider"
                {...CustomerTrustSlider}
              >
                {Customers.CustomersArray.map((item, index) => {
                  return (
                    <div key={index} className="item">
                      <Col lg={12}>
                        <div className="CustomerTrustContent">
                          <div className="Icon">
                            <i className="fa fa-user"></i>
                          </div>
                          <p className="CustomerTrustTxt">
                            {item.CustomerTrustTxt}
                          </p>
                          <div className="CustomerBottom">
                            <div className="LeftImg">
                              <img src={item.PersonImg} alt="" />
                            </div>
                            <div className="RightContent">
                              <strong>{item.John}</strong>
                              <span>
                                {item.KlarityC} <br></br> {item.Disease}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  );
                })}
              </OwlCarousel>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* CustomerTrustSlider */}

      {/* faq */}
      <Faqs {...FAQ} />
      {/* faq end */}

      {/* over 10000 */}
      <Container fluid className="over10000Bg">
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="over10000Head mb-5">{Over.over10000Head}</h2>
            {/* carosel */}
            <OwlCarousel className="owl-theme OverSlider" {...OverSlider}>
              {Over.OverItem.map((item, index) => {
                return (
                      <div key={index} className="overCarosuelBox">
                        <img
                          className="img-fluid mb-4"
                          src={item.overBoxImg}
                          alt=""
                        />
                        <h5 className="overBoxHead">{item.overBoxHead}</h5>
                        <p className="overBoxPara">{item.overBoxPara}</p>
                        <button className="overBoxBtn">
                          {item.overBoxBtn}
                        </button>
                        <hr />
                        <p className="overBoxPara mb-0">{item.Duke}</p>
                      </div>
                );
              })}
            </OwlCarousel>
            ;{/* carosel end */}
            <p className="over10000Para">
              The Visionology Medical Advisory Board consists of America’s
              leading board-certified eye doctors and surgeons. These renowned
              professionals and thought-leaders guide our approach to ensuring
              patient access to Visionology’s affordable and high quality
              treatments.
            </p>
            <div className="text-center mt-5">
              <img src="https://place-hold.it/174x30" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* over 10000 end */}

      {/* blog */}
      <Blog {...Blogs} />
      {/* blogend */}
    </Fragment>
  );
};

export default HeroSection;
