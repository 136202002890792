import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MedicationBtn from "../HomePage/medicationBtn/MedicationBtn";
import Medications from "../HomePage/medications/Medications";
import "./Page7.css";
import {
  page7Header,
  FdaSec1,
  FdaSec2,
  FdaSec3,
  Aqueous,
  Medication,
  StartYourBtn,
} from "./data7";

const Page7 = () => {
  return (
    <Fragment>
      {/* page7header */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={5} lg={6}>
                <h1 className="Page7Heading">{page7Header.headerHead}</h1>
                <p className="Page7SubPara">
                  <img
                    className="img-fluid me-2"
                    src={page7Header.headerPImg}
                    alt=""
                  />{" "}
                  {page7Header.headerSmPara}{" "}
                  <strong>{page7Header.headerSmParaStrong}</strong>
                  {page7Header.headerSmParaEnd}
                </p>
                <p className="Page7Para">{page7Header.headerPara}</p>
                <button className="Page7RedBtn mb-5">
                  {page7Header.headerBtn}
                </button>
              </Col>
              <Col md={7} lg={6}>
                <h3 className="page7CommonHead">{page7Header.headerSubHead}</h3>
                <Row>
                  <Col sm={6} className="mb-4">
                    <div className="page7HeadBox">
                      <h5 className="page7HeadBoxHead">
                        {page7Header.headerb1Head}
                      </h5>
                      <button className="page7HeadBoxBtn">Learn More</button>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <div className="page7HeadBox">
                      <h5 className="page7HeadBoxHead">
                        {page7Header.headerb2Head}
                      </h5>
                      <button className="page7HeadBoxBtn">Learn More</button>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <div className="page7HeadBox">
                      <h5 className="page7HeadBoxHead">
                        {page7Header.headerb3Head}
                      </h5>
                      <button className="page7HeadBoxBtn">Learn More</button>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <div className="page7HeadBox">
                      <h5 className="page7HeadBoxHead">
                        {page7Header.headerb4Head}
                      </h5>
                      <button className="page7HeadBoxBtn">Learn More</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* page7header end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FdaSec1.fdaHead}</h2>
                <p className="FdaPara">{FdaSec1.fdaPara}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FdaSec1.fdaImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FdaSec2.fdaHead}</h2>
                <p className="FdaPara">{FdaSec2.fdaPara}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FdaSec2.fdaImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* FDA Section */}
      <Container fluid className="FdaSectionBg">
        <Row>
          <Col xs={11} className="max-width mx-auto py-5">
            <Row className="py-5">
              <Col lg={6}>
                <h2 className="FdaHead py-5 mt-5">{FdaSec3.fdaHead}</h2>
                <p className="dryEyeParaItalic">{FdaSec3.fdaPara}</p>
                <div className="dryEyeStarWrapper">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
                <p className="FdaPara">{FdaSec3.fdaPara2}</p>
              </Col>
              <Col lg={6} className="text-center">
                <img className="img-fluid" src={FdaSec3.fdaImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* FDA Section end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">{Aqueous.AqueousHead}</h2>
                <p className="aqueousPara">{Aqueous.AqueousPara}</p>
                <p className="aqueousPara">{Aqueous.AqueousPara2}</p>
              </Col>
              <Col md={4} className="text-center">
                <img className="img-fluid" src={Aqueous.AqueousImg} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">Causes of Dry Eye Inflammation</h2>
                <p className="aqueousPara">
                  Dry eye inflammation is any immune response that causes dry
                  eye swelling in or around the eyes. If there’s inflammation in
                  the tear or lacrimal glands that line your lids, cornea (your
                  eye's clear outer layer), or conjunctiva (outermost layers of
                  your eyes), your body may not make enough tears or your tears
                  may not contain the right mix of water, oils, and salts.
                </p>
                <p className="aqueousPara">
                  An ocular surface disorder, dry eye disease happens when your
                  eyes don’t produce enough tears or if they make the wrong kind
                  of tears. The medical name for dry eye disease is
                  keratoconjunctivitis sicca.
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src="https://place-hold.it/298x298"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* Aqueous tear */}
      <Container fluid>
        <Row>
          <Col xs={11} className="max-widthAqueous mx-auto py-5">
            <Row>
              <Col md={8}>
                <h2 className="aqueousHead">Treatment Options</h2>
                <p className="aqueousPara">
                  Dry eye inflammation is any immune response that causes dry
                  eye swelling in or around the eyes. If there’s inflammation in
                  the tear or lacrimal glands that line your lids, cornea (your
                  eye's clear outer layer), or conjunctiva (outermost layers of
                  your eyes), your body may not make enough tears or your tears
                  may not contain the right mix of water, oils, and salts.
                </p>
                <p className="aqueousPara">
                  An ocular surface disorder, dry eye disease happens when your
                  eyes don’t produce enough tears or if they make the wrong kind
                  of tears. The medical name for dry eye disease is
                  keratoconjunctivitis sicca.
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  className="img-fluid"
                  src="https://place-hold.it/298x298"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Aqueous tear end */}

      {/* how section */}
      <Container fluid className="SparcsWork HowPage7Bg py-sm-5">
        <Row className="">
          <Col xs={11} className="mx-auto max-width ">
            <Row className="">
              <Col lg={12} className="mx-auto">
                <h1 className="BannerW3s_Heading text-center mb-sm-5 pb-5">
                  How Klarity-C Eye Drops Can Help
                </h1>
              </Col>
              <Col md={6} className="">
                <div className="SparcsWorkContent">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    Lubricates The Eye
                  </h1>
                  <p className="BannerSub_Description mt-4">
                    Klarity is an eye lubricant that keeps your eye moist, helps
                    protect the eye from injury and infection, and decreases the
                    symptoms of dry eyes such as burning, itching and feeling
                    that something in the eye.
                  </p>
                </div>
                <div className="SparcsWorkContent mt-5">
                  <h1 className="BannerSub_heading SWorkCHeading">
                    Increases Natural Tear Production
                  </h1>
                  <p className="BannerSub_Description mt-4">
                    Using cyclosporine, Klarity-C increases your natural tear
                    production which can help reduce eye dryness and other dry
                    eye symptoms.
                  </p>
                </div>
                <ul className="dryEyeHeaderList mt-5">
                  <li className="headList1">Preservative-free medication</li>
                  <li className="headList2">Relief within X Days</li>
                  <li className="headList3">
                    Made with doctor trusted, FDA approved drug components
                  </li>
                </ul>
              </Col>
              <Col md={6} className="">
                <div className="SparcsWorkCImg"></div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-center my-5 pt-5">
                  <button className="HeroBtn mt-0 me-4">
                    Start Free Online Visit
                  </button>
                  <button className="medicationPhoneBTN">Learn More</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* how section end */}

      {/* blog */}
      <Container fluid>
        <Row>
          <Col xs={11} className="mx-auto max-width py-5">
            <h2 className="blogHead">Featured Articles</h2>
            <Row className="mt-5">
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div>
                  <img
                    className="img-fluid"
                    src="https://place-hold.it/380x206"
                    alt=""
                  />
                  <p className="blogHeadPara">HEALTH TIPS</p>
                  <h4 className="blogBoxHead">
                    The Different types of Dry Eye Disease
                  </h4>
                  <p className="blogBoxPara">
                    After a physician has reviewed your medical instake form,
                    review your treatment options and find the best treatment
                    plan for your eyes.
                  </p>
                  <button className="blogBoxBtn">Read More</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* blogend */}

      {/* caring eye */}
      <Container fluid className="page7CaringBG">
        <Row className="py-5">
          <Col xs={11} className="max-width mx-auto py-5">
            <Row>
              <Col md={6}>
                <h2 className="page7CaringHead mt-5">
                  Caring for your eyes shouldnt be hard.
                </h2>
                <p className="page7CaringPara">
                  No more frequent doctor visits or unreasonably priced
                  medication. Visionology offers eye care treatments at
                  affordable prices by offering eye medication directly to you
                  from Imprimus, our FDA Approved pharmacy. By removing middle
                  men from the supply chain, Visionology can offer medications
                  up to 80% off of what you are currently paying.
                </p>
                <button className="Page7RedBtn">About Us</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* caring eyeend */}

      {/* medication */}
      <Medications {...Medication} />
      <MedicationBtn {...StartYourBtn} />
      {/* medication end */}
    </Fragment>
  );
};

export default Page7;
