import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./HomepageHero.css";

const HomepageHero = (props) => {
  const { AffordableEye, EmotionalCopy, Start } = props;
  return (
    <Fragment>
      {" "}
      {/* HeroSection */}
      <Container fluid>
        <Row className="py-5">
          <Col xs={11} className="mx-auto max-width HeroSecWrapper">
            <Row>
              <Col lg={6} className="mb-5 mb-lg-0">
                <h1 className="HeroHead">{AffordableEye}</h1>
                <p className="HeroPara">{EmotionalCopy}</p>
                <button className="HeroBtn">
                  <a href="https://uatquiz.visionology.com" style={{color: "inherit",
textDecoration: "none"}} target={'_blank'} rel="noreferrer" >{Start}</a>
                </button>
              </Col>
              <Col lg={6}>
                <Row>
                  
                  <Col md={12}>
                  <div className="DryEyeWrapper">
                      <h3 className="dryEyeHead">Dry Eye Disease</h3>
                      <button className="dryEyeBtn">Learn More</button>
                    </div>
                    <div className="diagnosticWrapper">
                      <h3 className="dryEyeHead">SPARCS Diagnostic</h3>
                      <button className="dryEyeBtn">Learn More</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* HeroSection end */}
    </Fragment>
  );
};

export default HomepageHero;
