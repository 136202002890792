import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./HeroSectionBreak.css";

const HeroSectionBreak = (props) => {
  const { HeroSectionbreak } = props;
  return (
    <Fragment>
      {/* HeroSectionBreak */}
      <Container fluid className="HeroSectionBreakBg mt-5">
        <Row>
          <Col xs={11} className="mx-auto max-width">
            <Row className="justify-content-around HeroBreakTransform">
              {HeroSectionbreak.map((item, index) => {
                return (
                  <Col key={index} lg={4} className="heroBreakBox  mb-4">
                    <img
                      className="img-fluid mb-2"
                      src={item.FormulatedImg}
                      alt=""
                    />
                    <p className="HeroSectionBreakPara">{item.Formulated}</p>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* HeroSectionBreak end */}
    </Fragment>
  );
};

export default HeroSectionBreak;
