const page6Header = {
  head6: "Intro: Why Doctors Should Sign Up",
  para6:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is more than just a nuisance. With medication prices ranging upwards of $300 and no insurance coverage, many may choose to put off treatment.",
  List1: "Free Online Visit",
  List2: "FDA-Registered Pharmacy",
  List3: "Unlimited Physician Follow Up",
  page6BtnBefore: "First Month of Medication for Only $1",
  para6Btn: "Start Free Online Visit",
};
const page6BlueBox = {
  page6BlueBoxHead: "How It Works",
  page6BlueBoxArray: [
    {
      justImgText: "",
      just3Text: "Reason Number One",
      just3Para:
        "Get connected with a physician by starting off with our online form.",
    },
    {
      justImgText: "",
      just3Text: "Reason Number Two",

      just3Para:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
    },
    {
      justImgText: "",
      just3Text: "Reason Number Three",
      just3Para:
        "If appropriate, your provider can prescribe treatment and we’ll ship it right to your door.",
    },
  ],
};

const HowSParcs = {
  HowSParcsHead: "Additional Benefits of Signing Up",
  HowSParcsSubHead: "Benefit 1",

  HowSParcsPara:
    "Klarity is an eye lubricant that keeps your eye moist, helps protect the eye from injury and infection, and decreases the symptoms of dry eyes such as burning, itching and feeliing.",
  HowSParcsSubHead2: "Benefit 2",
  HowSParcsPara2:
    " Using cyclosporine, Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  HowSParcsSubHead3: "Benefit 3",
  HowSParcsPara3:
    " Klarity-C is produced in small batches, meaning no preservatives are required or used in its formulation. This ensures freshness of product and minimal irritation for severe dry eye cases.",
  HowSParcsBtn: "Start Free Online Visit",
  HowSParcsbtn2: "Learn More",
};

const smallFaq = {
  smallFaqHead: "Frequently Asked Questions",
  smallFaqPara:
    "For more information about dry eye disease, Visionology, ImprimusRX and more, visit our FAQ page. To speak to a care representative, give us a call at ",
  smallFaqSpan: "(888) 616-6161",
  smallFaqSpan2: "hello@visionology.com",
  smallFaqBtn: "Start Free Online Visit",
  FAQArray: [
    {
      AccordionBtn: " How do I find the right information faster?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "How do I start the online intake form?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "What are estimated shipping times?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "What will I be charged after the first month?",

      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
  ],
};
const Over = {
  over10000Head: "Current Doctor Program Spotlight",
  OverItem: [
    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://place-hold.it/120x120",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
  ],
};
export { page6Header, page6BlueBox, HowSParcs, smallFaq, Over };
