import React from "react";
import "./Progress.css";
const Progress = () => {
  return (
    <div class="topbar_">
      <div class="topbar-parrent_ mt-4 mx-auto">
        <div class="ProgressBar"></div>
      </div>
    </div>
  );
};

export default Progress;
