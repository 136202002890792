import React, {useEffect} from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import "./Step6.css";
import Progress from "../../common/Progress/Progress";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step6 = ({ nextStep, prevStep, handleFormData, updateInputData, values }) => {
  const [YesActive, setYesActive] = React.useState(values.everBeenDiagnosed);
  const YesActiveHandler = (key) => {
    switch (key) {
      case "Dry":
        setYesActive((prevState) => ({
          ...prevState,
          Dry: true,
        }));
        break;
      case "glaucoma":
        setYesActive((prevState) => ({
          ...prevState,
          glaucoma: true,
        }));
        break;
      case "Cataracts":
        setYesActive((prevState) => ({
          ...prevState,
          Cataracts: true,
        }));
        break;
      case "Retinal":
        setYesActive((prevState) => ({
          ...prevState,
          Retinal: true,
        }));
        break;
      case "conditions":
        setYesActive((prevState) => ({
          ...prevState,
          conditions: true,
        }));
        break;
      default:
    }
  };
  const NoActiveHandler = (key) => {
    switch (key) {
      case "Dry":
        setYesActive((prevState) => ({
          ...prevState,
          Dry: false,
        }));
        break;
      case "glaucoma":
        setYesActive((prevState) => ({
          ...prevState,
          glaucoma: false,
        }));
        break;
      case "Cataracts":
        setYesActive((prevState) => ({
          ...prevState,
          Cataracts: false,
        }));
        break;
      case "Retinal":
        setYesActive((prevState) => ({
          ...prevState,
          Retinal: false,
        }));
        break;
      case "conditions":
        setYesActive((prevState) => ({
          ...prevState,
          conditions: false,
        }));
       
        break;
      default:
    }
  };
  useEffect(() => {
    updateInputData("everBeenDiagnosed",YesActive);
  });
  return (
    <section id="desktop3" className="desktop3 py-2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-11 max-width-q section-body_ mx-auto">
            <div className="row">
              <Headers {...headerData} />
              {/* <!-- body Content --> */}
              <div className="col-md-12 body-border_ PaddingX2rem">
                {/* <!-- topbar start --> */}
                <Progress />
                {/* <!-- topbar end -->
                <!-- body text --> */}
                {/* <!-- body text --> */}
                <div className="first-text_ pt-3">
                  Have you ever been diagnosed with:
                </div>
                {/* <!-- first box  --> */}
                <div className="row first-box_ mt-4 mb-3 mx-auto">
                  <div className="col-sm-6 my-3 align-self-center">
                    <div className="first-text_ text-center text-sm-start">
                      Dry Eye Disease
                    </div>
                  </div>
                  <div className="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.Dry ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "Dry")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "Dry")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.Dry ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- first box end --> */}

                {/* <!-- Second box  --> */}
                <div className="row first-box_ mb-3 mx-auto">
                  <div className="col-sm-6 my-2 align-self-center">
                    <div className="first-text_ text-center text-sm-start">
                      Glaucoma
                    </div>
                  </div>
                  <div className="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.glaucoma ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "glaucoma")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "glaucoma")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.glaucoma ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- Second box end --> */}

                {/* <!-- third box  --> */}
                <div className="row first-box_ mb-3 mx-auto">
                  <div className="col-sm-6 my-2 align-self-center">
                    <div className="first-text_ text-center text-sm-start">
                      Cataracts
                    </div>
                  </div>
                  <div className="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.Cataracts ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "Cataracts")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "Cataracts")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.Cataracts ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- third box end --> */}

                {/* <!-- four box  --> */}
                <div className="row first-box_ mb-3 mx-auto">
                  <div className="col-sm-6 my-2 align-self-center">
                    <div className="first-text_ text-center text-sm-start">
                      Retinal Detachment/Disease
                    </div>
                  </div>
                  <div className="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.Retinal ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "Retinal")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "Retinal")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.Retinal ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- four box end --> */}

                {/* <!-- five box  --> */}
                <div className="row first-box_ mb-3 mx-auto">
                  <div className="col-sm-6 my-2 align-self-center">
                    <div className="first-text_ text-center text-sm-start">
                      Other eye conditions
                    </div>
                  </div>
                  <div className="col-sm-6 my-3 text-center text-sm-end">
                    <div className=" ">
                      <button
                        className={`btn first1-btn_ py-1 px-4 me-1 ${
                          YesActive.conditions ? "ActiveBtn" : null
                        }`}
                        onClick={YesActiveHandler.bind(this, "conditions")}
                      >
                        Yes
                      </button>
                      <button
                        onClick={NoActiveHandler.bind(this, "conditions")}
                        className={`btn second2-btn_ py-1 px-4 ms-1 ${
                          YesActive.conditions ? null : "ActiveBtn"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                {/*  */}
                <FooterBtn
                  className="MarginTop8"
                  Next={nextStep}
                  Back={prevStep}
                />
                {/*  */}
              </div>
              {/* <!-- Footer start --> */}
              <Footers {...footerData} />
              {/* <!-- Footer End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Desktop 3 End --> */}
    </section>
  );
};

export default Step6;
