import React from "react";
import MainLogo from "../../../Asset/MainLogo.png";
const Index = () => {
  return (
    //    <!-- bar-line- max-width -->
    <div class="col-md-12 text-center">
      <a href="/">
        <img src={MainLogo} alt="" class="img-fluid" />
      </a>
    </div>
  );
};

export default Index;
