const Homepagehero = {
  AffordableEye: "Affordable Eye Care delivered with ease and precision.",
  EmotionalCopy: "Emotional copy that speaks to why eye care need to be treated with care. Visionology knows what it takes and has created products that does just that.",
  Start: "Start Free Online Visit",
};
const HeroSectionbreak = [
  {
    Formulated: "Formulated by our own FDA approved Pharmacy",
    FormulatedImg: "https://uat.visionology.com/images/home/group-35.png",
  },
  {
    Formulated: "Formulated by our own FDA approved Pharmacy",
    FormulatedImg: "https://uat.visionology.com/images/home/group-53.png",
  },
  {
    Formulated: "Formulated by our own FDA approved Pharmacy",
    FormulatedImg: "https://uat.visionology.com/images/home/group-41.png",
  },
];
const HomepageAboutus = {
  Caring: " Caring for your eyes shouldnt be hard.",
  frequent:
    "No more frequent doctor visits or unreasonably priced medication. Visionology offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain, Visionology can offer medications up to 80% off of what you are currently paying.",
  About: "About Us",
  frequentImg: "https://uat.visionology.com/images/home/about-us.png",
};
const Medication = {
  medicationsHead: "With Visionology medications:",
  MedicationArray: [
    {
      Noprior: "Formulated by our own FDA approved Pharmacy",
      NopriorImg: "https://uat.visionology.com/images/home/group-48.png",
    },
    {
      Noprior: "No preservatives in our ingredients",
      NopriorImg: "https://uat.visionology.com/images/home/group-50.png",
    },
    {
      Noprior: "Prescriptions reviewed by licensed physicians",
      NopriorImg: "https://uat.visionology.com/images/home/group-52.png",
    },
  ],
};
const Just3Step = {
  justThreeHead: "Just 3 Easy Steps",
  justThreeHeadArray: [
    {
      stepCss: "just3BoxImg1",
      justImgText: "1",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg2",
      justImgText: "2",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
    {
      stepCss: "just3BoxImg3",
      justImgText3: "3",
      just3Text: "Start online intake form",
      just3Para:
        " Get connected with a physician by starting off with our online form.",
    },
  ],
};
const DryEye = {
  DryEyeHeading: " Dry Eye Disease",
  Treatment: "Treatment: Klarity-C Eye Drops",
  millions:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance.",
  Visionology:
    "Visionology offers an affordable dry eye solution, Klarity-C Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  Learn: "Learn More About Treatments",
  DryeyeImg: "https://uat.visionology.com/home/dry-eye-disease.jpg",
};
const RedDryEye = {
  DryEyeHeading: " Dry Eye Disease",
  Treatment: "Treatment: Klarity-C Eye Drops",
  millions:
    "If you’re one of the millions of people who suffer from dry eye disease, you know this condition is much more than just a nuisance.",
  Visionology:
    "Visionology offers an affordable dry eye solution, Klarity-C Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
  Learn: "Learn More About Treatments",
  DryeyeImg: "https://place-hold.it/580x556",
};
const SparcDiagnostic = {
  SPARC: "SPARC Diagnostic",
  SPARCScontrast:
    "The SPARCS contrast sensitivity test is a new eye exam that focuses specifically on the ability to perceive contrast between light and dark. Developed under the direction of Dr. George Spaeth, SPARCS is currently being tested both as a measure of general eye health and as a preventative tool in relation to specific ocular diseases",
  dryEyeDPara: [
    "Step 1: Lorem Ipsum",
    "Step 2: Lorem Ipsum",
    "Step 3: Lorem Ipsum",
  ],
  HeroBtn: "Learn More About SPARC Diagnostic",
  Learn: "Learn More About Treatments",
  SPARCImg: "https://uat.visionology.com/images/home/sparcs-diagnostics-learn-more.jpg",
};
const CommonEye = {
  CommonEyeHead: "Common Eye Symptoms",
  SPARCScontrast:
    "Sometimes basic eye symptoms can be indicative of a larger condition. Learn about some of the most common eye symptoms and start a free online visit to find out if you have an eye condition. Early diagnosis and preventative are incredibly important for eye health.",
  DryEyes: [
    { image:"commonEyeBox1",  DryHead: "Dry Eyes", DryBtn: "Learn More" },
    { image:"commonEyeBox2",  DryHead: "Inflammation", DryBtn: "Learn More" },
    { image:"commonEyeBox3",  DryHead: "Stinging or Burning", DryBtn: "Learn More" },
    { image:"commonEyeBox4",  DryHead: "Red Eyes", DryBtn: "Learn More" },
    { image:"commonEyeBox5",  DryHead: "Blurry Vision", DryBtn: "Learn More" },
    { image:"commonEyeBox6",  DryHead: "Light Sensitivity", DryBtn: "Learn More" },
  ],
};
const Customers = {
  CustomersHeading: "FAQs",
  CustomersArray: [
    {
      CustomerTrustTxt:
        " I have suffered from Dry Eye Disease for 30+ years.Tried most of the eye drops available. I received a sample of Visionology's drops.They work better than any of the others I've tried.",
      John: "John Smith",
      KlarityC: "Klarity-C ",
      Disease: "Dry Eye Disease",
      PersonImg: "https://uat.visionology.com/images/home/oval.png",
    },
    {
      CustomerTrustTxt:
        " I have suffered from Dry Eye Disease for 30+ years.Tried most of the eye drops available. I received a sample of Visionology's drops.They work better than any of the others I've tried.",
      John: "John Smith",
      KlarityC: "Klarity-C ",
      Disease: "Dry Eye Disease",
      PersonImg: "https://uat.visionology.com/images/home/oval.png",
    },
    {
      CustomerTrustTxt:
        " I have suffered from Dry Eye Disease for 30+ years.Tried most of the eye drops available. I received a sample of Visionology's drops.They work better than any of the others I've tried.",
      John: "John Smith",
      KlarityC: "Klarity-C ",
      Disease: "Dry Eye Disease",
      PersonImg: "https://uat.visionology.com/images/home/oval.png",
    },
    {
      CustomerTrustTxt:
        " I have suffered from Dry Eye Disease for 30+ years.Tried most of the eye drops available. I received a sample of Visionology's drops.They work better than any of the others I've tried.",
      John: "John Smith",
      KlarityC: "Klarity-C ",
      Disease: "Dry Eye Disease",
      PersonImg: "https://uat.visionology.com/images/home/oval.png",
    },
  ],
};
const FAQ = {
  FAQHead: " Why Our Customers Trust Us",
  FAQArray: [
    {
      AccordionBtn: " How do I find the right information faster?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "How do I start the online intake form?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
    {
      AccordionBtn: "What are estimated shipping times?",
      AccordionDescription:
        "This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.",
    },
  ],
};
const Over = {
  over10000Head:
    " Over 10,000 physicians across America sugges patients to buy eye medications from Visionology",
  OverItem: [
    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/blake-williamson.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/ChristopherStarrMD.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/cynthiaMatossian.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/edwardHolland.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/EricDonnenfeld.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },

    {
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/JesseRichman.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/JohnBerdahl.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/julieSchallhorn.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/kellyNichols.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/Lindstrom.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/Okeke.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/paulKarpecki.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/preeyagupta.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/terryKim.jpeg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
	
	{
      overBoxHead: "Terry Kim, M.D",
      overBoxImg: "https://uat.visionology.com/images/MAB/williamWiley.jpg",
      overBoxPara: "Cornea & Refractive Surgeon",
      overBoxBtn: "Learn More",
      Duke: "Duke Eye Center",
    },
  ],
};
const Blogs = {
  blogHead: "Featured Articles",
  BlogItem: [
    {
      BlogImg: "https://uat.visionology.com/images/Blog/allergies-and-dry-eye-disease.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/covid-dry-eyes.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
    {
      BlogImg: "https://uat.visionology.com/images/Blog/dry-eye-disease-guide.jpg",
      HEALTH: "HEALTH TIPS",
      TheDifferent: "The Different types of Dry Eye Disease",
      AfteraPhysician:
        "After a physician has reviewed your medical instake form, review your treatment options and find the best treatment plan for your eyes.",
      BlogReadMore: "Read More",
    },
  ],
};
export {
  Homepagehero,
  HeroSectionbreak,
  HomepageAboutus,
  Medication,
  Just3Step,
  DryEye,
  RedDryEye,
  SparcDiagnostic,
  CommonEye,
  Customers,
  FAQ,
  Over,
  Blogs,
};
