import React, { Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import "./Header.css";
const Header = () => {
  // sidebar
  const [stikyValue, setstikyValue] = React.useState(false);

  const OpenSidebarHandler = (e) => {
    e.preventDefault();
    $(".navbar-collapse").addClass("add_width");
  };
  const CloseSidebarHandler = (e) => {
    e.preventDefault();
    $(".navbar-collapse").removeClass("add_width");
  };
  //
  $(window).scroll(function () {
    if ($(window).scrollTop() <= 420) {
      setstikyValue(false);
    } else {
      setstikyValue(true);
    }
  });
  return (
    <Fragment>
      {/* HeroSection */}
      <Container
        fluid
        className={`Header py-3 ${stikyValue ? "navbar-sticky" : null}`}
      >
        <Row>
          <Col xs={11} className="mx-auto max-width">
            <Row>
              <Col sm={12}>
                <div class="navbar navbar-expand-lg py-0 px-0">
                  <a href="/" class="navbar-brand me-4">
                    <img src="https://uat.visionology.com/images/logo-visionology.png" alt="" />
                  </a>
                  <button class="navbar-toggler" onClick={OpenSidebarHandler}>
                    <i class="fa fa-bars " aria-hidden="true"></i>
                  </button>

                  <div class="collapse navbar-collapse show" id="my_navbar">
                    <div>
                      <a
                        className="close_btn"
                        onClick={CloseSidebarHandler}
                        href="sss"
                      >
                        &times;
                      </a>
                    </div>
                    <ul class="navbar-nav Left_CustomNav mr-auto">
                      <li class="nav-item">
                        <NavLink to={`/how-it-works`} className="nav-link">
                          How It Works
                        </NavLink>
                        {/* <a href="/"></a> */}
                      </li>

                      <li class="nav-item DropDownIcon">
                        <NavLink to={`/about`} className="nav-link">
                          <span className="me-2"> About Us</span>
                          <i class="fa fa-angle-down " aria-hidden="true"></i>
                        </NavLink>
                        {/* <a href="/" class="nav-link"></a> */}
                        <ul className="DropDownCustom shadow-sm border">
                          <li>
                            <NavLink to={`/5th`} className="nav-link">
                              {/* <a href="/"></a> */}
                              Page 5
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`/dry-eye-disease`} className="nav-link">
                              {/* <a href="/"></a> */}
                              Condition
                            </NavLink>
                            {/* <a href="/">DropDown1</a> */}
                          </li>
                          <li>
                            <NavLink to={`/dry-eyes`} className="nav-link">
                              {/* <a href="/"></a> */}
                              Dry Eyes
                            </NavLink>
                            {/* <a href="/">DropDown1</a> */}
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item DropDownIcon">
                        {/* <a href="/" class="nav-link"> */}
                        <NavLink to={`/imprimisRx`} className="nav-link">
                          <span className="me-2"> Treatments </span>
                          <i class="fa fa-angle-down " aria-hidden="true"></i>
                        </NavLink>
                        {/* </a> */}
                      </li>
                    </ul>
                    <ul class="navbar-nav ms-auto Right_CustomNav">
                      <li class="nav-item">
                        <a href="/" class="nav-link baricon">
                          <i class="fa fa-bars " aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="nav-item customarSupport">
                        <a href={`tel:+1(844) 637 2020`}>
                          <i className="fa fa-phone"></i>
                          <span>Customer Support</span>
                        </a>
                      </li>
                      <li class="nav-item LoginBtn">
                        <a href="/" class="">
                          Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Header;
