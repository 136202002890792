const VisionLoss = {
  Vision: "Vision Loss",
  Medically: " Medically Reviewed by ",
  st: "Whitney Seltman, OD.",
  Deecember: "December 21, 2020",
  Copy: "Copy about Dry Eyes and how it can be an indicator of other symptoms. Copy about Dry Eyes and how it can be an indicator of other symptoms.",
  Start: " Start Free Online Visit",
  Common: "Common Conditions That We Treat That May Result in Vision Loss",
  DryArr: [
    { Dry: "Dry Eye Disease", Learn: "Learn More" },
    { Dry: "Dry Eye Disease", Learn: "Learn More" },
  ],
};
const FDALoss = {
  What: "What Causes Vision Loss?",
  Medically:
    "Dry eye inflammation is any immune response that causes dry eye swelling in or around the eyes. If there’s inflammation in the tear or lacrimal glands that line your lids, cornea (your eye's clear outer layer), or conjunctiva (outermost layers of your eyes), your body may not make enough tears or your tears may not contain the right mix of water, oils, and salts.,",
  CopyImg: "https://place-hold.it/580x580",
};
const FDALoss1 = {
  What: "Sudden Vision Loss",
  Medically:
    "An ocular surface disorder, dry eye disease happens when your eyes don’t produce enough tears or if they make the wrong kind of tears. The medical name for dry eye disease is keratoconjunctivitis sicca.",
  CopyImg: "https://place-hold.it/580x580",
};
const FDALoss2 = {
  What: "Gradual Vision Loss",
  Medically:
    "An ocular surface disorder, dry eye disease happens when your eyes don’t produce enough tears or if they make the wrong kind of tears. The medical name for dry eye disease is keratoconjunctivitis sicca.",
  CopyImg: "https://place-hold.it/580x580",
};
const Aqueous = {
  AqueousHead: "Common Causes of Vision Loss",
  ListOut:
    "List out the different conditions that can result in condition loss. Examples, but not limited to.",
  ListGlumam: [
    {
      aqueousSubHead: "Glaucoma",
      aqueousPara:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
    {
      aqueousSubHead: "Glaucoma",
      aqueousPara:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
    {
      aqueousSubHead: "Glaucoma",
      aqueousPara:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
    {
      aqueousSubHead: "Glaucoma",
      aqueousPara:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
    {
      aqueousSubHead: "Glaucoma",
      aqueousPara:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
  ],
};
const Aqueoustear = {
  AqueousHead: "Common Causes of Vision Loss",

  ListGlumam: [
    {
      ListOut:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },

    {
      ListOut:
        "List out the different conditions that can result in condition loss. Examples, but not limited to.",
    },
  ],
};
const Treatment = {
  AqueousHead: "Treatment Options",
  Different:
    " List out the different conditions that can result in condition loss. Examples, but not limited to. List out the differentconditions that can result in condition loss. Examples, butnot limited to. List out the different conditions that canresult in condition loss. Examples, but not limited to.",
  DifferentImg: "https://place-hold.it/298x298",
};
const SPARCStEST = {
  SPARCS: "SPARCS Test",
  SPARCS1:
    " List out the different conditions that can result in condition loss.",
  SPARCS2:
    " List out the different conditions that can result in condition loss.",
  DifferentImg: "https://place-hold.it/298x298",
};
const Klarity = {
  KlarityhEAD: " How Klarity-C Eye Drops Can Help",
  KLArr: [
    {
      Lubricates: "Lubricates The Eye",
      KlarityDescript:
        "Klarity is an eye lubricant that keeps your eye moist, helps protect the eye from injury and infection, and decreases the symptoms of dry eyes such as burning, itching and feeling that something in the eye.",
    },
    {
      Lubricates: " Increases Natural Tear Production",
      KlarityDescript:
        " Using cyclosporine, Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
    },
    {
      Lubricates: "Increases Natural Tear Production",
      KlarityDescript:
        " Using cyclosporine, Klarity-C increases your natural tear production which can help reduce eye dryness and other dry eye symptoms.",
    },
  ],
};
const caring = {
  caringhEAD: "Caring for your eyes shouldnt be hard.",
  caringDesript:
    "  No more frequent doctor visits or unreasonably priced medication. Visionology offers eye care treatments at affordable prices by offering eye medication directly to you from Imprimus, our FDA Approved pharmacy. By removing middle men from the supply chain, Visionology can offer medications up to 80% off of what you are currently paying.",
};
const Medication = {
  medicationsHead: "With Visionology medications:",
  MedicationArray: [
    {
      Noprior: "Formulated by our own FDA approved Pharmacy",
      NopriorImg: "https://place-hold.it/85x73",
    },
    {
      Noprior: "No preservatives in our ingredients",
      NopriorImg: "https://place-hold.it/85x73",
    },
    {
      Noprior: "Prescriptions reviewed by licensed physicians",
      NopriorImg: "https://place-hold.it/85x73",
    },
  ],
};
const StartYourBtn = {
  StartYourBtn: "Start free Online Visit",
  StartYourBtn2Before: "Speak to a Representative:",
  StartYourBtn2: "123-456-7890",
};
export {
  VisionLoss,
  FDALoss,
  FDALoss1,
  FDALoss2,
  Aqueous,
  Aqueoustear,
  Treatment,
  SPARCStEST,
  Klarity,
  caring,
  Medication,
  StartYourBtn,
};
