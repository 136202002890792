import React, { useState, useEffect } from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import $ from "jquery";

import "./Step4.css";
import Progress from "../../common/Progress/Progress";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step4 = ({ nextStep, prevStep, handleFormData, updateInputData, values  }) => {
  const [RangeSlider, setRangeSlider] = useState(values.dailyLifeEffectRange);
  const [val, setval] = useState(values.dailyLifeEffectRange1);
  const RangeSliderHandler = (e) => {
    setRangeSlider(e.target.value);
    const value = e.target.value;
    var attr = $("#myinput").attr("max");
    var w1 = parseInt(attr) + 6;
    var w2 = w1.toString();
    var left = (value / w2) * 100 + "%";
    $(".thumb").css("left", left);
    $("#hello").val(value);
    if (value < 91) {
      setval((parseInt(value) + 10) / 10);
    }
    updateInputData("dailyLifeEffectRange",value);
    updateInputData("dailyLifeEffectRange1",(parseInt(value) + 10) / 10);
  };
  useEffect(() => {
    if (val) {
      var attr = $("#myinput").attr("max");
      var w1 = parseInt(attr) + 6;
      var w2 = w1.toString();
      var left = (RangeSlider / w2) * 100 + "%";
      $(".thumb").css("left", left);
    }
  });
  return (
    //    <!-- Desktop 3 Start -->
    <section id="desktop3" class="desktop3 py-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-11 max-width-q section-body_ mx-auto">
            <div class="row">
              <Headers {...headerData} />
              {/* <!-- body Content --> */}
              <div class="col-md-12 body-border_ PaddingX2rem">
                {/* <!-- topbar start --> */}
                <Progress />
                {/* <!-- topbar end -->
                <!-- body text --> */}
                <div class="first-text_ pt-3">
                  Please click the number (1-10) that best describes your
                  <span className="fw"> Dry Eye Symptoms</span> over the past
                  week.
                </div>
                <div class="first-text_ fw my-5 pb-3">
                  How do your Dry Eye Symptoms <br />
                  affect your daily life?
                </div>

                {/* <!-- body text --> */}
                {/* Slider */}
                <div className="inputwrapper">
                  <span id="hello" class="thumb">
                    {val}
                  </span>
                  <input
                    type="range"
                    // className="RangeS_Input"
                    className="slider"
                    name="foo"
                    max="90"
                    min="0"
                    value={RangeSlider}
                    id="myinput"
                    onChange={RangeSliderHandler}
                  />
                </div>
                <div class="ten-numbers_ px-1 py-1">
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                  <div>6</div>
                  <div>7</div>
                  <div>8</div>
                  <div>9</div>
                  <div>10</div>
                </div>
                <div class="first-text_ fw my-5 pt-3">Sometimes affected</div>
                {/*  */}
                <FooterBtn
                  className="MarginTop12"
                  Next={nextStep}
                  Back={prevStep}
                />
                {/*  */}
              </div>
              {/* <!-- Footer start --> */}
              <Footers {...footerData} />
              {/* <!-- Footer End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Desktop 3 End --> */}
    </section>
  );
};

export default Step4;
