import React from "react";
import Headers from "../../common/Headers/Headers";
import Footers from "../../common/Footers/Footers";
import FooterBtn from "../../common/FooterBtn/FooterBtn";
import Progress from "../../common/Progress/Progress";
import "./Step2.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
const Step2 = ({ nextStep, prevStep }) => {
  return (
    //    <!-- Desktop 3 Start -->
    <section class="py-2">
      <div class="container-fluid ">
        <div class="row">
          <div class="col-11 max-width-q section-body_ mx-auto">
            <div class="row">
              <Headers {...headerData} />
              {/* <!-- body Content --> */}
              <div class="col-md-12 body-border_ PaddingX2rem">
                {/* <!-- topbar start --> */}
                <Progress />
                {/* <!-- body text --> */}
                <div class="scnd-text- py-3">Dry Eye Assessment</div>
                <div class="dummy-text- py-2 pe-3">
                  How do your <strong>Dry Eye Symptoms</strong> affect your
                  daily life? <br />
                  <br />
                  <strong>Dry Eye Symptoms</strong> like pain, burning, tearing,
                  grittiness, and sensitivity to light may affect your ability
                  to read, drive, work on a computer or watch TV.
                </div>
                {/*  */}
                <FooterBtn
                  className="MarginTop20"
                  Next={nextStep}
                  Back={prevStep}
                />
              </div>
              {/* <!-- Footer start --> */}
              <Footers {...footerData} />
              {/* <!-- Footer End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Desktop 3 End --> */}
    </section>
  );
};

export default Step2;
